import BackButton from '@src/components/BackButton';
import DashboardFrame from '@src/components/DashboardFrame';
import { useEffect, useRef, useState } from 'react';
import defaultAvatar from '@icons/avatar.png';
import cameraIcon from '@icons/camera.png';
import { Form, Input } from 'antd';
import { Select } from '../../components/Select';
import { Button as CustomButton } from '@src/pages/acquisition/pricing/components/ui/button';
import { UserDetailsData } from '@src/core/interfaces/user';
import { GetUser, updateUserDetails } from '@src/core/endpoints/user';
import { toast } from 'react-toastify';
import UpdatePasswordModal from './update_password_modal';
import { uploadProfilePicture } from '../../function/uploadProfile';
import CircularProgress from '@mui/material/CircularProgress';
import { useUserContext } from '@src/context/UseProvider';

interface profileInputProps {
    name: string;
    label: string;
    disabled: boolean;
    placeholder?: string;
    isList?: boolean;
    options?: string[];
}

const ProfileScreen = () => {
    const [isMobile, setIsMobile] = useState(true);
    const [loading, setLoading] = useState(false);
    const [profilePic, setProfilePic] = useState<string>('');
    const [showPassModal, setShowPassModal] = useState(false);
    const [picloading, setpicLoading] = useState(true);
    const [form] = Form.useForm();
    const { userDetails, refreshUserDetails } = useUserContext();
    const [visible, setIsVisible] = useState(false);

  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true); // Load image once it enters the viewport
            observer.disconnect(); // Stop observing once the image is loaded
          }
        });
      },
      { threshold: 0.1 } // Load when 10% of the image is visible
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [])

    const inputArray: profileInputProps[] = [
        {
            name: 'email',
            label: 'Email',
            disabled: true,
            isList: false,
        },
        {
            name: 'fname',
            label: 'First Name',
            disabled: false,
            isList: false,
            placeholder: 'Kindly enter your first name',
        },
        {
            name: 'lname',
            label: 'Last Name',
            disabled: false,
            isList: false,
            placeholder: 'Kindly enter your last name',
        },
        {
            name: 'role',
            label: 'What bests fits your role?',
            disabled: false,
            isList: true,
            options: ['', 'Administrator', 'Teacher', 'Designer', 'Student', 'Guardian'],
        },
        {
            name: 'lang',
            label: 'Choose your preferred language',
            disabled: false,
            isList: true,
            options: ['', 'English', 'Russian', 'Dutch', 'Danish'],
            placeholder: 'Select language',
        },
    ];

    const [inputValues, setInputValues] = useState<{ [key: string]: string }>({
        email: '',
        fname: '',
        lname: '',
        role: '',
        lang: '',
    });

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const onValueChange = (value: string, key: string) => {
        setInputValues((prevValues) => ({
            ...prevValues,
            [key]: value,
        }));
    };

    useEffect(() => {
        console.log(inputValues);
    }, [inputValues]);

    const onRefreshPageDetails = async () => {
        const token = localStorage.getItem('token');
        if (!token) return;
        const details = await GetUser(token);
        const detailsFetched: UserDetailsData = details.data;
        onFetchDetails(detailsFetched);
    };

    const onUpdateDetails = async () => {
        try {
            setLoading(true);
            const dataToSend = {
                firstName: inputValues['fname'],
                lastName: inputValues['lname'],
                role: inputValues['role'],
                language: inputValues['lang'],
            };

            const updated = await updateUserDetails(dataToSend);

            if (updated.statusCode != 1) {
                toast.error(updated.message ?? 'Error updating details');
            } else {
                toast.success('Data updated successfully');
                await onRefreshPageDetails();
            }
        } catch (error) {
            console.error(error);
            toast.error('Error updating details');
        } finally {
            setLoading(false);
        }
    };

    const onFetchDetails = (details: UserDetailsData) => {
        // prefile details
        const detailsFetched = {
            email: details.email,
            fname: details.firstName,
            lname: details.lastName,
            role: details.role,
            lang: details.language,
        };

        setProfilePic(details.profilePic);

        console.log(detailsFetched);

        form.setFieldsValue(detailsFetched);

        setInputValues(detailsFetched);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0];

        console.log('Selected file:', file);

        if (file) {
            handleProfilePictureUpload(file).then(() => {
                // Clear the input after upload
                fileInput.value = ''; // Reset the file input
                refreshUserDetails();
            });
        }
    };
    const handleProfilePictureUpload = async (file: File) => {
        console.log('Uploading profile picture...'); // Add this line
        try {
            setLoading(true);
            const result = await uploadProfilePicture(file);
            if (result.success) {
                toast.success(result.message);
                onRefreshPageDetails();
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error('An error occurred while uploading the profile picture');
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardFrame
            showSidebar={!isMobile}
            showHistory={!isMobile}
            showTop={!isMobile}
            history={[]}
            selectedHistory={(v: string) => {
                console.log(v);
            }}
            showHistoryOption={false}
            showPagePath={false}
            onDeleteHistory={(id: string) => {}}
            onDeleteAllHistory={() => {}}
            canNavigateHome={true}
            onDetailsFetched={(e: UserDetailsData) => onFetchDetails(e)}
        >
            <section className="w-full h-full py-8 px-4 md:px-8 flex flex-col gap-y-5">
                <BackButton />
                <div className="mx-5 md:mx-[6.25rem] flex-col gap-y-3">
                    <div className="flex flex-col md:flex-row gap-x-[2rem] md:gap-x-[5rem] h-auto  md:h-[19rem]">
                        {/* avatar image  */}
                        <div className="w-[13rem] flex flex-col items-center md:items-start h-full self-center md:self-start">
                            <p className="hidden md:flex text-[0.875rem] text-[var(--gmind-deep-grey)] font-semibold">
                                Photo
                            </p>
                            <div className="w-full relative h-[12.5rem]">
                               
                                <img
                                    className="w-full h-full rounded-[50%]"
                                    src={userDetails?.profilePic  ? userDetails.profilePic : defaultAvatar}
                                    alt="avatar image"
                                    loading="lazy"
                                />
                                <label
                                    htmlFor="fileInput"
                                    className="absolute w-[2rem] h-[2rem] right-5 bottom-2 cursor-pointer"
                                >
                                    <img className="w-full h-full" src={cameraIcon} alt="Camera Icon" />
                                    <input
                                        id="fileInput"
                                        type="file"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                    />
                                </label>
                            </div>
                        </div>

                        <div className="h-full w-full md:w-[32.25rem]">
                            <Form
                                form={form}
                                layout="vertical"
                                className="flex flex-col md:flex-row flex-wrap gap-y-2 gap-x-3 w-full"
                                onFinish={() => {}}
                            >
                                {inputArray.map((item, index) => (
                                    <Form.Item
                                        key={index}
                                        className={`w-full ${item.name == 'fname' || item.name == 'lname' ? 'md:w-[48.5%]' : 'md:w-full'} mb-1`}
                                        name={item.name}
                                        label={<label>{item.label}</label>}
                                    >
                                        {item.isList && (item.options ?? []).length > 0 ? (
                                            <Select
                                                className="w-full rounded-[0.25rem]"
                                                onSelect={(e) => onValueChange(e, item.name)}
                                                options={item.options ?? []}
                                                placeholder={item.placeholder}
                                                value={inputValues[item.name]}
                                            />
                                        ) : (
                                            <Input
                                                disabled={item.disabled}
                                                className="w-full"
                                                placeholder={item.label}
                                                style={{
                                                    border: '1px solid #E5E8F1',
                                                    borderRadius: '4px',
                                                    backgroundColor: 'transparent',
                                                    padding: '7px 10px',
                                                }}
                                                value={inputValues[item.name]}
                                                onChange={(e) => onValueChange(e.target.value, item.name)}
                                            />
                                        )}
                                    </Form.Item>
                                ))}
                            </Form>
                        </div>
                    </div>

                    <div
                        className={`ml-3 mt-5 ${!isMobile ? 'mr-24' : ''} flex flex-col md:flex-row items-start justify-start md:justify-between gap-y-4`}
                    >
                        <CustomButton
                            onClick={() => setShowPassModal(true)}
                            key="ok"
                            variant={'outline'}
                            className={`bg-[#A5A3AE30] text-[#333237] ${isMobile ? 'w-full' : ''} `}
                        >
                            Update Password
                        </CustomButton>

                        <CustomButton
                            onClick={onUpdateDetails}
                            key="ok2"
                            disabled={loading}
                            variant={'outline'}
                            className={`bg-[var(--gmind-orange)] text-white ${isMobile ? 'w-full' : ''}`}
                        >
                            Save
                        </CustomButton>
                    </div>

                    {/* update password and save */}
                </div>
                {/* modal for update Password */}
                <UpdatePasswordModal isVisible={showPassModal} onCancel={() => setShowPassModal(false)} />
            </section>
        </DashboardFrame>
    );
};
export default ProfileScreen;
