/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import logo from '@assets/gmind.png';
import avatar from '@assets/default_user.png';
import collapse from '@assets/collapse.svg';
import Option from './option';
// import ask from '@assets/brand-hipchat.svg';
import ask from '@icons/brand-hipchat.svg';
import notes from '@assets/notes.svg';
import layout_grid from '@assets/layout-grid.svg';
import slideshow from '@assets/slideshow.svg';
import writing from '@assets/writing-sign.svg';
import image from '@assets/picture-in-picture.svg';
import transcriber from '@assets/transcriber.svg';
import activity from '@assets/activity.svg';
import speakerPhone from '@icons/speakerphone.svg';
import videoPlus from '@icons/video-plus.svg';
import { IoMdLogOut } from 'react-icons/io';
import file_search from '@assets/file-search.svg';
import { RxCross2 } from 'react-icons/rx';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
    AssessementImage,
    ContentImage,
    CurriculumImage,
    ImageGeneratorImage,
    SlideImage,
    SpeechImage,
    TranscriberImage,
    VideoMakerImage,
} from './images';
import { getUrlPath } from '@src/utils/app_functions';
import { UserDetailsData } from '@src/core/interfaces/user';
import { useUserContext } from '@src/context/UseProvider';

type OptionType = {
    icon: typeof ask;
    text: string;
    label: string;
    comingSoon?: boolean;
    element?: JSX.Element;
};
const Sidebar = ({
    selected,
    className,
    style,
    onMobileClose,
    collapsed,
    isMobile,
   
}: {
    selected: (v: string) => void;
    className?: string;
    style?: React.CSSProperties;
    collapsed?: (v: boolean) => void;
    onMobileClose?: () => void;
    isMobile?: boolean;
}) => {
    const optionsValue: OptionType[] = [
        { icon: ask, text: 'Ask G-mind AI', label: 'chat' },
        { icon: layout_grid, text: 'Curriculum Planner', label: 'curriculum', element: CurriculumImage() },
        // { icon: layout_grid, text: 'School Letters', label: 'curriculum', element: CurriculumImage() },
        { icon: writing, text: 'Content Generator', label: 'generate_content', element: ContentImage() },
        { icon: slideshow, text: 'Slides Maker', label: 'slide', element: SlideImage() },
        { icon: transcriber, text: 'Transcriber', label: 'transcriber', element: TranscriberImage() },
        {
            icon: speakerPhone,
            text: 'Speech Synthesizer',
            label: 'speech_synthesizer',
            comingSoon: false,
            element: SpeechImage(),
        },
        { icon: image, text: 'Image Generator', label: 'image_maker', element: ImageGeneratorImage() },
        { icon: videoPlus, text: 'Video Mode ', label: 'video_mode', comingSoon: true, element: VideoMakerImage() },
    ];
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    const [isCollapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const [userOptions, setUserOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [options, setOptions] = useState<OptionType[]>(optionsValue);
    const { userDetails, refreshUserDetails } = useUserContext();
  

   

    useEffect(() => {
        if (user.plan && user.plan?.restrictions) {
            const { slideMaker, speechToText } = user.plan.restrictions || {};
            const filteredOptions = optionsValue.filter((option) => {
                // if (option.comingSoon) return false;
                if (option.label === 'slide' && !slideMaker) return false;
                if (option.label === 'transcriber' && !speechToText) return false;
                return true;
            });

            setOptions(filteredOptions);
        } else if (user.freeTrialAvailable) {
            const filteredOptions = optionsValue.filter((option) => {
                // if (option.comingSoon) return false;
                if (option.label === 'slide') return true;
                if (option.label === 'transcriber') return true;
                return true;
            });

            setOptions(filteredOptions);
        } else {
            const filteredOptions = optionsValue.filter((option) => {
                // if (option.comingSoon) return false;
                if (option.label === 'slide') return false;
                if (option.label === 'transcriber') return false;
                return true;
            });

            setOptions(filteredOptions);
        }
    }, [window.location.pathname]);

    function collapseSideBar() {
        setCollapsed(!isCollapsed);
        collapsed && collapsed(!isCollapsed);
    }

    function handleLogout() {
        localStorage.clear();
        window.location.replace('/login');
    }

    useEffect(() => {
        const { pathname } = window.location;
        const selected = pathname.split('/').slice(-1);
        setSelectedOption(selected[0]);
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 820 && !isMobile) {
            setCollapsed(true);
        }
    }, [window.innerWidth]);
    return (
        <nav
            style={style}
            className={
                'relative transition-all bg-defaultWhiteLighter border-e border-gray-200 h-full sidebar flex flex-col p-4 px-0 pb-0 shadow-md ' +
                className
            }
        >
            <div
                style={isCollapsed ? { justifyContent: 'center', padding: '0' } : { justifyContent: 'space-between' }}
                className="flex px-6 items-center relative"
            >
                {!isCollapsed && (
                    <img
                        onClick={() => {
                            navigate('/dashboard/chat');
                        }}
                        role="button"
                        src={logo}
                        className="w-20"
                    />
                )}
                {isMobile && (
                    <RxCross2
                        role="button"
                        onClick={onMobileClose}
                        className="absolute text-2xl top-0 right-5 text-[#48474b]"
                    />
                )}
                <div role="button" onClick={collapseSideBar}>
                    {!isMobile && (
                        <img
                            style={isCollapsed ? { width: '1rem' } : {}}
                            role="button"
                            onClick={collapseSideBar}
                            src={collapse}
                            className="max-md:hidden w-[1.5rem]"
                        />
                    )}
                </div>
            </div>
            <div
                style={isCollapsed ? { padding: '0' } : { paddingBottom: '1.25rem ' }}
                className="mt-6 flex overflow-y-auto flex-col gap-2 w-full px-4 pb-5"
            >
                {options.map(({ icon, text, label, comingSoon, element }, index) => (
                    <Option
                        key={label}
                        isMobile={isMobile ?? false}
                        click={(v) => {
                            // if ( comingSoon ) return;
                            if (v === 'chat') {
                                localStorage.removeItem('activeChat');
                            }
                            setSelectedOption(v);
                            selected(v);
                        }}
                        collapsed={isCollapsed}
                        icon={icon}
                        selected={selectedOption}
                        text={isCollapsed ? '' : text}
                        label={label}
                        comingSoon={comingSoon ?? false}
                        index={index}
                        selectedElemen={getUrlPath().pagename == label ? element : undefined}
                    />
                ))}
            </div>

            <section
                role="button"
                style={isCollapsed ? { userSelect: 'none' } : {}}
                // onClick={() => !isCollapsed && setUserOptions(!userOptions)}
                onClick={() => navigate('/dashboard/profile')}
                className="bg-defaultWhiteLighter relative border-border-defaultWhiteLight mt-auto flex flex-col select-none transition-all h-[4rem] p-4 cursor-pointer gap-4 border"
            >
                <div className="flex items-start gap-2 transition-all">
                    <img
                    
                        src={userDetails?.profilePic ? userDetails?.profilePic : avatar}
                        className="w-7 h-7 max-lg:w-5 bg-cover bg-center rounded-full"
                        style={
                            isCollapsed
                                ? {
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                  }
                                : {}
                        }
                    />
                    {!isCollapsed && (
                        <>
                            <div className="flex flex-col">
                                <p className="font-medium text-xs max-lg:text-[0.6rem]">
                                    {user?.firstName ?? 'User'} {user?.lastName ?? ''}{' '}
                                </p>
                                <p className="text-[0.6rem] max-lg:hidden">{user?.email ?? ''}</p>
                            </div>
                        </>
                    )}
                </div>
            </section>
        </nav>
    );
};

export default Sidebar;
