import { Skeleton, Tag, Modal, Input } from 'antd';
import userIcon from '@assets/chat/user.svg';
import gmindIcon from '@public/GMind Logo 2.svg';
import useImageStore from '@src/providers/useImageStore';
import { generateImagesList, generateOpenAimage } from '../../pages/slide/helper';
import { useEffect, useState } from 'react';
import { uploadToCloudinary } from '../../pages/slide/upload';
import { Toaster, toast } from 'sonner';
import {
    ChevronLeft,
    MessageCircleMore,
    Repeat,
    ThumbsDown,
    ThumbsUp,
    Share2,
    Copy,
    Download,
    Edit,
    CreativeCommons,
    Edit3,
} from 'lucide-react';
import useFormStore from '@src/providers/formStore';
import CommentModal from './CommentModal';
import EditImageModal from './edit-image-modal';
import { useImageEditorStore } from '@src/providers/useImageEditorStore';
import { useNavigate } from 'react-router';
const { TextArea } = Input;
type Props = {};

const role = 'user';

function SecondScreen({}: Props) {
    const navigate = useNavigate();
    const { setImageUrl } = useImageEditorStore();
    const { previousStep } = useFormStore();
    const {
        prompt,
        generatedImages,
        imageType,
        clearGeneratedImages,
        setPrompt,
        addGeneratedImage,
        size,
        setImageType,
    } = useImageStore();
    const [load, setloading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [generatedImagUrl, setGeneratedImgUrl] = useState('');

    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [inputPrompt, setInputPrompt] = useState(prompt);
    const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newPrompt = e.target.value;
        setInputPrompt(newPrompt);
        setPrompt(newPrompt);
    };
    const handleEdit = () => {
        // setImageUrl(generatedImagUrl);
        // navigate('/dashboard/image_maker/edit');
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    // const uploadAndStoreImages = async (aiCommand: any) => {
    //     try {
    //         const aiImageUrls = await generateImagesList(aiCommand, 1, size ?? '1024x1024');
    //         console.log('here....');
    //         console.log(aiImageUrls);
    //         const imagePromises = aiImageUrls.map(async (imageData) => {
    //             const url = await uploadToCloudinary(imageData.b64_json);
    //             console.log('url:' + url);
    //             addGeneratedImage(url);
    //             console.log(generatedImages);
    //             return url;
    //         });

    //         await Promise.all(imagePromises);
    //     } catch (error: any) {
    //         console.error('Error in uploading and storing images:', error);
    //         toast.error('Error in uploading and storing images:', error.message);
    //     } finally {
    //         setloading(false);
    //     }
    // };
    // const uploadAndStoreImage = async (aiCommand: any) => {
    //     try {
    //         const aiImageUrls = await generateImagesList(aiCommand, 1, size ?? '1024x1024');
    //         console.log('here....');
    //         console.log(aiImageUrls);

    //         if (aiImageUrls.length > 0) {
    //             const imageData = aiImageUrls[0];
    //             const url = await uploadToCloudinary(imageData.b64_json);
    //             console.log('url:' + url);
    //             addGeneratedImage(url);
    //             console.log(generatedImages);
    //         }
    //     } catch (error: any) {
    //         console.error('Error in uploading and storing images:', error);
    //         toast.error('Error in uploading and storing images:', error.message);
    //     } finally {
    //         setloading(false);
    //     }
    // };
    const uploadAndStoreImage = async (aiCommand: any) => {
        try {
            const imageData = await generateOpenAimage(aiCommand, size ?? '1024x1024');
            // console.log('here....');
            // console.log(imageData);

            if (imageData) {
                const url = await uploadToCloudinary(imageData.data.data[0].b64_json);
                // console.log('url:' + url);
                setGeneratedImgUrl(url);
                addGeneratedImage(url);
                // console.log(generatedImages);
                toast.success('Image generated sucessfully');
                setloading(false);
                // setTimeout(() => {
                //     setShowModal(true);
                // }, 5000);
            }
        } catch (error: any) {
            console.error('Error in uploading and storing images:', error);
            toast.error('Error in uploading and storing images:', error.message);
        } finally {
            setloading(false);
        }
    };
    async function generateImages() {
        setloading(true);
        const refined_prompt = `${prompt}. Note, your image generation should be on this type: ${imageType} and ensure to check for spelling errors.`;
        await uploadAndStoreImage(refined_prompt);
    }
    const a = 'started';

    useEffect(() => {
        if (prompt && a) {
            generateImages();
        }
    }, [a]);

    function Back() {
        previousStep();
        clearGeneratedImages();
    }

    function handleRegenerate() {
        clearGeneratedImages();
        generateImages();
        if (!generatedImages) {
            toast.error('Unable to generate image at this time.');
        }
    }

    function handleImageClick(image: string) {
        setSelectedImage(image);
        setIsModalVisible(true);
    }

    function handleShare() {
        navigator
            .share({
                title: 'Generated Image',
                url: selectedImage ?? '',
            })
            .catch((error) => console.error('Error sharing:', error));
    }

    function handleCopy() {
        navigator.clipboard
            .writeText(selectedImage || '')
            .then(() => {
                toast.success('Image URL copied to clipboard');
            })
            .catch((error) => console.error('Error copying to clipboard:', error));
    }

    async function handleDownload() {
        if (!selectedImage) {
            toast.error('No image selected for download');
            return;
        }

        toast.info('Downloading...', { duration: 2000 });

        try {
            const response = await fetch(selectedImage);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = 'generated_image.png';
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);

            toast.success('Download complete');
        } catch (error) {
            console.error('Error downloading the image:', error);
            toast.error('Error downloading the image');
        }
    }
    function OpenCommentModal() {
        setIsCommentModalVisible(true);
    }
    const handleImageTypeChange = (event: any) => {
        const { value } = event.target;
        setImageType(value);
    };
    return (
        <section className="overflow-auto">
            {/* <EditImageModal visible={showModal} onEdit={handleEdit} onCancel={handleCancel} /> */}
            <button
                onClick={Back}
                className="text-center text-[12px] flex justify-center items-center mb-[2] pt-2 gap-3 "
            >
                <ChevronLeft className="bg-gray-200 rounded-full !font-[300] text-gray-700 !text-sm" />
                <span>Back</span>
            </button>
            <Toaster richColors />
            {/* section */}
            <div className="mt-8 md:mt-0 md:flex flex-col md:flex-row md:gap-8 ">
                <div className="md:flex md:flex-col md:w-full">
                    <div className="flex md:flex-row p-2 md:p-4 gap-4 bg-[#F9FAFC] rounded-lg items-center text-balance m-2 md:m-4 py-0">
                        {/* <img
                    src={userIcon}
                    className={"w-8 scale-[1.4]' : 'w-6 border border-black bg-[#001f73] rounded-full p-[0.2rem]"}
                /> */}
                        <Edit color="gray" />
                        <TextArea
                            // disabled={load}
                            value={inputPrompt}
                            onChange={handlePromptChange}
                            className="font-[400] text-[12px] !border-0 rounded-none  focus:shadow-none focus:border-0 w-full bg-[#F9FAFC] py-0"
                        />
                    </div>
                    <div className="mt-5 md:mt-0 flex justify-between items-center text-center ">
                        {/* <Tag>Image type: {imageType}</Tag> */}
                        <div className="flex gap-1 items-center !text-[12px]">
                            <label>Image Type:</label>
                            <select
                                // placeholder="Select Voice Model"
                                value={imageType || ''}
                                onChange={handleImageTypeChange}
                                className="!text-[12px] border p-[0.5px] rounded-sm"
                            >
                                <option value="" disabled>
                                    Select Image Type
                                </option>

                                <option value="avatars">Avatars</option>
                                <option value="anime">Anime</option>
                                <option value="realistic">Realistic</option>
                                <option value="cartoon">Cartoon</option>
                                <option value="abstract">Abstract</option>
                            </select>
                        </div>

                        <button
                            onClick={handleRegenerate}
                            className="flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                        >
                            <Repeat color="#E55109" className="!text-[14px] hover:cursor-pointer" size={'14px'} />
                            <small>Regenerate</small>
                        </button>
                    </div>
                </div>
                <div className="mt-4 flex flex-col gap-y-5 md:flex md:flex-col md:w-full  md:gap-3">
                    <div className="hidden relative md:flex flex-row justify-center items-center m-2 mt-0">
                        <img src={gmindIcon} className="w-16" />
                        <p className="absolute transform -translate-x-1/2 -translate-y-1/2 mt-5 ml-24 text-[#232323] font-[400]">
                            G-mind
                        </p>
                    </div>

                    <small className="text-center items-center justify-center flex">
                        Click on a generated image to have a larger view and select other options
                    </small>

                    <div>
                        {load ? (
                            <div>
                                <LoadingImageState />
                            </div>
                        ) : (
                            <div>
                                <GeneratedImages onImageClick={handleImageClick} />
                            </div>
                        )}
                    </div>
                    <div className="flex justify-center text-center items-center gap-8 p-4 mb-16">
                        <MessageCircleMore
                            color="#E55109"
                            className="!text-[14px] hover:cursor-pointer"
                            size={'14px'}
                            onClick={OpenCommentModal}
                        />
                        <ThumbsUp color="#E55109" className="!text-[14px] hover:cursor-pointer" size={'14px'} />
                        <ThumbsDown color="#E55109" className="!text-[14px] hover:cursor-pointer" size={'14px'} />
                        {/* <button
                            onClick={handleEdit}
                            disabled={load}
                            className=" flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                        >
                            <Edit3 size="14px" color="#E55109" />
                            <small>Edit Image</small>
                        </button> */}
                    </div>

                    <Modal
                        visible={isModalVisible}
                        footer={null}
                        onCancel={() => setIsModalVisible(false)}
                        bodyStyle={{ backgroundColor: '#F9FAFC', borderRadius: '1rem' }}
                        className="w-full"
                    >
                        {/* <div className="flex md:flex-row p-2 md:p-4 gap-4 bg-[#F9FAFC] rounded-lg items-center text-balance m-2 md:m-4">
                            <img
                                src={userIcon}
                                className={
                                    "w-8 scale-[1.4]' : 'w-6 border border-black bg-[#001f73] rounded-full p-[0.2rem]"
                                }
                            />
                            <p className="font-[400] text-[12px]">{prompt}</p>
                        </div>
                        <Tag>Image type: {imageType}</Tag> */}
                        {selectedImage && (
                            <img
                                src={selectedImage}
                                alt="Selected"
                                className="w-full h-auto rounded-md shadow-lg mt-4"
                            />
                        )}
                        <div className="flex justify-between text-center items-center gap-4 p-4 mt-4 !bg-white">
                            <div className="flex gap-2">
                                <button
                                    onClick={handleShare}
                                    className=" flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                                >
                                    <Share2 size="14px" color="#E55109" />
                                    <small>Share</small>
                                </button>
                                {/* <button
                                    className=" flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                                    onClick={handleCopy}
                                >
                                    <Copy size="14px" color="#E55109" />
                                    <small>Copy</small>{' '}
                                </button> */}
                                <button
                                    className=" flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                                    // icon={<Download />}
                                    onClick={handleDownload}
                                >
                                    <Download size="14px" color="#E55109" />
                                    <small>Download</small>{' '}
                                </button>
                                {/* <button
                                    onClick={handleEdit}
                                    className=" flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC] rounded-[12px]"
                                >
                                    <Edit3 size="14px" color="#E55109" />
                                    <small>Edit Image</small>
                                </button> */}
                            </div>
                            <div className="flex gap-2">
                                <Repeat
                                    color="#E55109"
                                    className="hover:cursor-pointer"
                                    size={'14px'}
                                    onClick={handleRegenerate}
                                />
                                <MessageCircleMore
                                    color="#E55109"
                                    className="hover:cursor-pointer"
                                    size={'14px'}
                                    onClick={OpenCommentModal}
                                />
                                <ThumbsUp color="#E55109" className="hover:cursor-pointer" size={'14px'} />
                                <ThumbsDown color="#E55109" className="hover:cursor-pointer" size={'14px'} />
                            </div>
                        </div>
                    </Modal>
                    {/* comment modal */}
                    <CommentModal isOpen={isCommentModalVisible} onClose={() => setIsCommentModalVisible(false)} />
                </div>
            </div>
        </section>
    );
}

export default SecondScreen;

function LoadingImageState() {
    return (
        <div className="justify-center flex flex-col items-center text-center">
            <div className="flex flex-wrap p-2 gap-4">
                <Skeleton.Image
                    active={true}
                    style={{
                        width: '13rem',
                        height: '13rem',
                        borderRadius: '1rem',
                    }}
                />
                {/* <Skeleton.Image
                    active={true}
                    style={{
                        width: '12rem',
                        height: '12rem',
                        borderRadius: '1rem',
                    }}
                /> */}
            </div>
            {/* <div className="flex flex-wrap p-2 gap-4">
                <Skeleton.Image
                    active={true}
                    style={{
                        width: '8rem',
                        height: '8rem',
                        borderRadius: '1rem',
                    }}
                />
                <Skeleton.Image
                    active={true}
                    style={{
                        width: '8rem',
                        height: '8rem',
                        borderRadius: '1rem',
                    }}
                />
            </div> */}
        </div>
    );
}

function GeneratedImages({ onImageClick }: { onImageClick: (image: string) => void }) {
    const { generatedImages } = useImageStore.getState();
    if (!generatedImages) {
        toast.error('Unable to generate image at this time.');
    }

    return (
        <div className="flex flex-col gap-4 flex-wrap justify-center items-center text-center">
            <div className="flex gap-4">
                {generatedImages.slice(0, 1).map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Generated ${index + 1}`}
                        className="w-60 h-60 rounded-md shadow-lg cursor-pointer"
                        onClick={() => onImageClick(image)}
                    />
                ))}
            </div>
            {/* <div className="flex gap-4">
                {generatedImages.slice(2, 4).map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Generated ${index + 3}`}
                        className="w-40 h-40 rounded-md shadow-lg cursor-pointer"
                        onClick={() => onImageClick(image)}
                    />
                ))}
            </div> */}
        </div>
    );
}
