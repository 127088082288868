
import { Input } from 'antd';
interface Props {
    value?:string;
    setValue?:React.Dispatch<React.SetStateAction<string>>;
    onClick?:()=>void;
    schoolAdminPageName?: string;  
    showInput?: boolean;
}

const SchoolHeader = ({value,setValue,schoolAdminPageName,onClick,showInput}: Props) => {
  
  return (
    <div className="w-full h-[87px] flex items-center justify-between pr-[45px] pl-[45px] bg-white">
      <div className="text-xl font-medium leading-7 text-left text-black">{schoolAdminPageName}</div>
      {showInput &&<Input value={value} onChange={(e)=> setValue && setValue(e.target.value)} placeholder="Search" className=" w-full md:w-[363px] h-[42px] border-2 rounded-lg" suffix={renderSearchIcon()} />}
      <div className="relative" onClick={onClick} style={{cursor:"pointer"}}>
        <div className="absolute h-[12px] w-[12px] bg-[#FEA450] rounded-full right-0 top-0 "></div>
        {renderNotificationIcon()}
      </div>
    </div>
  );
};
export default SchoolHeader;

const renderSearchIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 16L12.3809 12.3809M12.3809 12.3809C12.9999 11.7618 13.491 11.0269 13.826 10.218C14.1611 9.40917 14.3335 8.54225 14.3335 7.66676C14.3335 6.79127 14.1611 5.92435 13.826 5.1155C13.491 4.30665 12.9999 3.57172 12.3809 2.95265C11.7618 2.33358 11.0269 1.84251 10.218 1.50748C9.40917 1.17244 8.54225 1 7.66676 1C6.79127 1 5.92435 1.17244 5.1155 1.50748C4.30665 1.84251 3.57172 2.33358 2.95265 2.95265C1.70239 4.20291 1 5.89863 1 7.66676C1 9.4349 1.70239 11.1306 2.95265 12.3809C4.20291 13.6311 5.89863 14.3335 7.66676 14.3335C9.4349 14.3335 11.1306 13.6311 12.3809 12.3809Z"
        stroke="#E55109"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const renderNotificationIcon = () => {
    return (
      <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7431 2.80153C18.0086 1.31509 15.7604 0.414062 13.312 0.414062C7.84039 0.414062 3.409 4.84545 3.409 10.3171V13.0886C3.409 13.4063 3.3398 13.8634 3.20656 14.3466C3.07314 14.8304 2.89913 15.2548 2.7377 15.5223L2.73584 15.5253L1.05977 18.3232L1.05824 18.3258C0.38801 19.4514 0.249318 20.7152 0.663635 21.8367C1.07812 22.9587 2.00519 23.8272 3.24658 24.241L3.24724 24.2412C4.82364 24.7656 6.43105 25.1635 8.05451 25.435C8.32987 26.2676 8.79809 27.0146 9.39825 27.6148C10.3871 28.6036 11.7744 29.2343 13.287 29.2343C15.7174 29.2343 17.7926 27.6401 18.519 25.4456C20.1641 25.1739 21.793 24.7725 23.39 24.2412L23.3907 24.241C25.8518 23.4207 26.9045 20.5409 25.5778 18.3237L25.5775 18.3232L23.9014 15.5253L23.8996 15.5223C23.739 15.2561 23.5648 14.8294 23.431 14.3424C23.3177 13.9307 23.251 13.5399 23.2331 13.2413C22.4115 12.9882 21.6565 12.5829 21.0012 12.0583V13.0886C21.0012 13.6623 21.117 14.3271 21.2836 14.9328C21.4497 15.5371 21.6908 16.1718 21.9915 16.6708L21.9928 16.6729L23.6667 19.4672L23.667 19.4677C24.2666 20.4704 23.7887 21.7604 22.6871 22.128C16.6069 24.1505 10.0304 24.1505 3.95018 22.128C3.29153 21.9083 2.91226 21.4968 2.75271 21.0649C2.59303 20.6327 2.61259 20.069 2.97119 19.4662L2.97179 19.4651L4.64449 16.6729L4.64556 16.6711C4.94554 16.1735 5.18687 15.5429 5.3535 14.9386C5.5205 14.333 5.63607 13.6682 5.63607 13.0886V10.3171C5.63607 6.07543 9.07036 2.64113 13.312 2.64113C15.4146 2.64113 17.3301 3.5024 18.7233 4.88932C18.9424 4.1301 19.2908 3.4257 19.7431 2.80153ZM10.973 26.04C10.8867 25.9537 10.8051 25.8624 10.7286 25.7667C12.431 25.9053 14.142 25.907 15.8447 25.7719C15.2412 26.523 14.3163 27.0072 13.287 27.0072C12.3977 27.0072 11.5679 26.6349 10.973 26.04ZM14.3984 7.3877C14.3984 6.77271 13.8999 6.27416 13.2849 6.27416C12.6699 6.27416 12.1714 6.77271 12.1714 7.3877V11.7825C12.1714 12.3974 12.6699 12.896 13.2849 12.896C13.8999 12.896 14.3984 12.3974 14.3984 11.7825V7.3877Z"
          fill="#E55109"
        />
      </svg>
    );
  };