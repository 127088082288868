import { BASE_URL } from '@src/utils/constants';

interface UserDetails {
    email: string;
    docId: string;
    role: string;
}

export const changeRole = async (userDetails: UserDetails) => {
    if (!userDetails) return;
    const token = localStorage.getItem('token');

    try {
        const response = await fetch(`${BASE_URL}/document/change-collaborator-role`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(userDetails),
        });
        console.log('Error:', response);

        const apiResponse = await response.json();

        console.log(apiResponse);

        // Check if the response is successful
        if (apiResponse.statusCode == 0) {
            return 'User role has been changed sucessfully';
        } else {
            const errorMessage = `Error: ${apiResponse.message}`;

            return errorMessage;
        }
    } catch (error) {
        console.error('Error posting user details:', error);
    }
};

export const deleteCollaborator = async (email: string, docId: string) => {
    const userDetails = {
        email: email,
        docId: docId,
    };
    if (!userDetails) return;
    const token = localStorage.getItem('token');

    try {
        const response = await fetch(`${BASE_URL}/document/remove-collaborator`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(userDetails),
        });

        // Check if the response is successful
        if (response.status == 200) {
            const data = await response.json();
            console.log('User removed Sucessfully', data);

            return 'User removed Sucessfully';
        } else {
            const errorMessage = `Error: ${response.status} ${response.statusText}`;

            return errorMessage;
        }
    } catch (error) {
        console.error('Error posting user details:', error);
    }
};

export const postInvite = async (userDetails: UserDetails) => {
    if (!userDetails) return;
    const token = localStorage.getItem('token');

    try {
        const response = await fetch(`${BASE_URL}/document/invite-collaborator`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(userDetails),
        });

        // Check if the response is successful
        if (response.status == 200) {
            const data = await response.json();
            console.log('User details successfully posted:', data);

            return 'User details successfully posted';

            // Clear error if request is successful
        } else {
            const apiResponse = await response.json();
            const errorMessage = `Error: ${apiResponse.message} `;
            console.log(errorMessage);
            return errorMessage;
        }
    } catch (error) {
        console.error('Error posting user details:', error);
    }
};
