
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import"./file.scss"

const { Dragger } = Upload;
interface FileUploaderProps {
    uploadedFile: File|null; // Define the type for uploadedFiles
    setUploadedFile: React.Dispatch<React.SetStateAction<File|null>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FileUploader = ({ uploadedFile, setUploadedFile,loading,setLoading }: FileUploaderProps) => {


    

    const props = {
        name: 'file',
        multiple: false, // Only allow one file
        accept: 'image/*', // Only accept image files
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        beforeUpload: (file: File) => {
          return false; // Prevent automatic upload
        },
        onChange(info: any) {
          const { status } = info.file;
    
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
    
          // Allow only one file to be uploaded at a time
          if (info.fileList.length > 0) {
            setUploadedFile(info.fileList[0].originFileObj); // Store the first file
            setLoading(false); // Set loading to false
          } else {
            setUploadedFile(null); // Clear the file if none are selected
          }
        },
        onDrop(e: React.DragEvent<HTMLDivElement>) {
          console.log('Dropped file', e.dataTransfer.files[0]);
        },
      };

    return (
        <div className="w-full h-full flex flex-col justify-center items-center">
            <Dragger className='.custom-dragger border-[#ed5405]' style={{borderColor:'#ed5405'}} {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{color:"#ed5405"}} />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other banned
                    files.
                </p>
            </Dragger>
        </div>
    );
};

export default FileUploader;
