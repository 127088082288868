/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import send from '@assets/send.svg';
import microphone from '@assets/microphone.svg';
import photoPicker from '@icons/photo.svg';
import cancelImg from '@assets/cancel.svg';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Input as FormInput } from 'antd';
import { uploadChatPicture } from '../endpoints';
import { Spin, Skeleton } from 'antd';

const Input = ({
    value,
    showLeftField,
    placeholder = 'Ask your question',
    img,
    setImg,
    onPaste,
}: {
    value: (v: string, img: string) => void;
    showLeftField?: boolean;
    placeholder?: string;
    img: File| null;
    onPaste: (e: any) => void;
    setImg: React.Dispatch<React.SetStateAction<File | null>>;
}) => {
    const inputRef = useRef<any>();
    const recognitionRef = useRef<any>(null);
    const [recording, setRecording] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Value, setValue] = useState('');
    const [rows, setRows] = useState(1);
    const [imageSelected, setImageSelected] = useState('');
    const [uploading, setUploading] = useState(false);

    const imageRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // setIsVisible(true); // Load image once it enters the viewport
                        observer.disconnect(); // Stop observing once the image is loaded
                    }
                });
            },
            { threshold: 0.1 }, // Load when 10% of the image is visible
        );

        if (imageRef.current) {
            observer.observe(imageRef.current);
        }

        return () => {
            if (imageRef.current) {
                observer.unobserve(imageRef.current);
            }
        };
    }, []);

    useEffect(()=>{
        if (img) {
            handlePictureUpload(img).then(() => {
                setImg(null)
            });
        }
    },[img])

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0];

        console.log('Selected file:', file);

      setImg(file??null);
    };

    const handlePictureUpload = async (file: File) => {
        try {
            setUploading(true);
            const result = await uploadChatPicture(file);
            if (result.success) {
                setImageSelected(result.url ?? '');
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error('An error occurred while uploading the picture');
        } finally {
            setUploading(false);
        }
    };

    const calculateRows = (e: any) => {
        const textarea = e.target;
        const lineBreaks = (textarea.value.match(/\n/g) || []).length;

        const scrollHeight = textarea.scrollHeight;
        const computedStyle = window.getComputedStyle(textarea);
        const lineHeight = parseInt(computedStyle.lineHeight, 10) || 20;
        const newRows = Math.ceil(scrollHeight / lineHeight);

        setRows(Math.max(lineBreaks, newRows));
    };

    const handleInput = (e: any) => {
        setValue(e.target.value);
        calculateRows(e); // Update rows on input
    };

    const startRecording = () => {
        try {
            toast.success('Recording in progress...');
            setRecording(true);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            recognitionRef.current = new window.webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setValue(texts.join(' '));
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const stopRecording = () => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                recognitionRef.current.stop();
                setRecording(false);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        return () => {
            // Stop the speech recognition if it's active
            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
        };
    }, []);

    function handleSent() {
        if (Value.trim() == '' && imageSelected == '') return;
        value(Value, imageSelected);
        setValue('');
        setImageSelected('');
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSent();
        }
    }

    async function handleMicrophone() {
        if (recording) {
            stopRecording();
        } else {
            startRecording();
        }
    }

    return (
        <div className="space-y-2 max-md:h-fit flex flex-col max-md:py-1 bg-white">
            <section className="flex max-md:flex-col items-center gap-2 h-full">
                {showLeftField && (
                    <input
                        type="text"
                        className="border flex-1 h-12 placeholder:font-normal placeholder:text-gray-400 placeholder:text-xs w-full basis-[20%] border-[#f5b99d] transition-all focus:border-brandOrange p-2 px-4 text-nowrap rounded-xl  text-xs flex items-center outline-none max-md:py-4"
                        placeholder="Enter your field"
                    />
                )}
                <div
                    style={
                        loading
                            ? { animation: 'pulse 1s ease infinite', userSelect: 'none' }
                            : { userSelect: 'auto', animation: 'none' }
                    }
                    className={`flex flex-col gap-y-2 border bg-white border-gray-400 rounded-2xl h-full w-full px-3`}
                >
                    {uploading && !imageSelected ? (
                        <div className="mx-5 mt-5 w-16 h-16 rounded-md relative">
                            <SkeletonLoader />
                        </div>
                    ) : (
                        imageSelected && (
                            <div className="mx-5 mt-5 w-16 h-16 rounded-md relative">
                                <img src={imageSelected} alt="" className="w-full h-full rounded-md" />
                                <div
                                    className="w-6 h-6 border border-gray-400 rounded-full bg-white absolute right-[-10px] top-[-10px]"
                                    onClick={(e) => {
                                        setImageSelected('');
                                    }}
                                >
                                    <img src={cancelImg} alt="cancel" loading="lazy" className="w-full h-full" />
                                </div>
                            </div>
                        )
                    )}

                    <div className={`flex flex-1 basis-[80%] ${rows > 3 ? 'items-end' : 'items-center'}`}>
                        <div className="flex items-center gap-x-2">
                            {!recording && (
                                <img
                                    onClick={() => {
                                        if (loading) {
                                            toast.info('Relax, your speech will soon be converted to text 🙂');
                                        }
                                        !loading && handleMicrophone();
                                    }}
                                    role="button"
                                    src={microphone}
                                    className="flex-1 basis-[5%] w-full h-6"
                                />
                            )}
                            {recording && (
                                <BounceLoading
                                    onClick={() => {
                                        if (loading) {
                                            toast.info('Relax, your speech will soon be converted to text 🙂');
                                        }
                                        !loading && handleMicrophone();
                                    }}
                                    className="h-6 w-6 flex-1 basis-[5%]"
                                />
                            )}
                            <label htmlFor="fileInput" className="flex-1 basis-[5%] w-full md:h-8 h-12 cursor-pointer">
                                <img
                                    onClick={() => {
                                        // handle image picker
                                    }}
                                    role="button"
                                    src={photoPicker}
                                    className="w-full h-full"
                                />
                                <input
                                    id="fileInput"
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                />
                            </label>
                        </div>

                        <div className="py-2 w-full mx-2">
                            <FormInput.TextArea
                                ref={inputRef}
                                onPaste={onPaste}
                                onInput={(e: any) => {
                                    handleInput(e);
                                    setValue(e.target.value);
                                }}
                                value={Value}
                                onKeyDown={handleKeyDown}
                                // type="text"
                                className="w-full min-h-[80px] flex-1 basis-[90%] outline-none border-none focus:ring-0 no-resize-textarea border-gray-300 text-sm px-2 placeholder:font-normal placeholder:text-gray-400 placeholder:text-sm"
                                placeholder={placeholder}
                                disabled={loading}
                                autoSize={{ minRows: 1, maxRows: 5 }}
                            />
                        </div>

                        <img
                            onClick={handleSent}
                            role="button"
                            src={send}
                            alt="send"
                            className="block w-full h-7 flex-1 basis-[5%]  send"
                        />
                    </div>
                </div>
            </section>
            <p className=" text-gray-400 text-xs">
                Gmind can make mistakes. It's advisable to verify crucial information.
            </p>
        </div>
    );
};
const BounceLoading = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
    return (
        <section
            role="button"
            onClick={onClick && onClick}
            className={`bounce_parent flex justify-center items-center gap-[0.1rem] ${className}`}
        >
            <div className="bounce rounded-xl h-full w-2 bg-brandOrange"></div>
            <div className="bounce rounded-xl h-full w-2 bg-brandOrange"></div>
            <div className="bounce rounded-xl h-full w-2 bg-brandOrange"></div>
        </section>
    );
};

const SkeletonLoader = () => {
    return (
        <div className="relative w-full h-full bg-gray-200 animate-pulse rounded-md">
            <div className="absolute inset-0 bg-gray-300 animate-pulse rounded-md"></div>
        </div>
    );
};

export default Input;
