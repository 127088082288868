/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { toast } from 'react-toastify';
import Profile from '@assets/Avatar.svg';
import { MyProfileIcon } from '@src/components/Svg';
import LoadingComponent from '@src/components/LoadingComponent';
import ShareImage from '@public/share.svg';
import Copy from '@public/copy.svg';
import DownloadImage from '@public/download.svg';
import DashboardFrame from '@src/components/DashboardFrame';
import MarkdownDesign from '@src/components/Markdown';
import { io, Socket } from 'socket.io-client';
import { ChatType, getHistory, handleAddHistory, handleDeleteAllHistory, handleDeleteHistory } from '../../endpoints';
import { prompts } from '@src/utils/prompts';
import { convertStringToDelta, copyData, openEditor, shareResponse } from '@src/utils/app_functions';
import ResponseTextbox from '../../components/responseTextBox';
import axios from 'axios';
import { Share } from '../../components/Share';
import { Download } from '../../components/Share/Download';
import moment from 'moment';
import saveAs from 'file-saver';
import { pdfExporter } from 'quill-to-pdf';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router';
import { Chats } from '../../components/interface/interface';
import ReusableDialog from '../../components/comment_modal';
import BackButton from '@src/components/BackButton';
import ContentTypingComponent from '../../components/content_typing';
import AssessmentHome from './components/assessment_home';
import { BASE_URL, socketUrl } from '@src/utils/constants';
import { HistoryTypeFormat } from '@src/core/interfaces/chat';

const Assessment = () => {
    const [rememberMe, setRememberMe] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [histories, setHistories] = useState<any>([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    // socket.io
    const [response, setResponse] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [promptSent, setPromptSent] = useState('');
    const [newPromptSent, setNewPromptSent] = useState('');
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [isMobile, setIsMobile] = useState(true);
    const UrlRef = useRef<string>('');
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [canNavigate, setCanNavigate] = useState(false);
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [topic, setTopic] = useState('');
    const [audience, setAudience] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({role: 'assistant', content: ''});

    

    const pageHistory = 'assessments';
    const navigate = useNavigate();
    const baseurl = BASE_URL;

    const bottomRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if ( isTyping && response != '' ){
            console.log("scrolling")
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
        
    }, [isTyping, response]);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const getPageHistory = () => {
        getHistory(pageHistory).then((response) => {
            if (response?.statusCode === 1) {
                const data = response?.data;
                // const filtered = data?.map((c: any) => {
                //     return [...c.conversation, { id: c._id, date: c.createdAt }];
                // });
                const filtered: HistoryTypeFormat[]  = data?.map((d: any) => {
                    return {
                            'date': d.date,
                            'histories': d.histories?.map((history: any) => {
                                return [
                                    ...history.conversation,
                                    { chat_id: history?.chat_id },
                                    { id: history._id, },
                                    { date: history.updatedAt },
                                ];
                            })
                        }
                    
                });
                setHistories(filtered);
            }else {
                setHistories([]);
            }
        });
    };

    const setClickedHistory = (id: string) => {
        // const filterHistory = histories
        //     .filter((h: any) => {
        //         const { id: history_id } = h[h.length - 1];
        //         return String(history_id) === String(id);
        //     })
        //     .flat()
        //     .filter((h: any) => !h?.id);

        //     setPromptSent(filterHistory[filterHistory.length -2].content)
        //     if ( filterHistory.length > 1 ){
        //         setResponse(filterHistory[filterHistory.length - 1].content)
        //     }
        let filterHistory: any = histories
            .flatMap((historyFormat: any) => historyFormat.histories)
            .filter((history: any) => {
                const chatIdObj = history.find((h: any) => h.id === id);
                return chatIdObj !== undefined;
            })
            .flat();

        // historyId.current = filterHistory.find((h: any) => h.chat_id)?.chat_id;
        filterHistory = filterHistory.filter((h: any) => h?.role && h?.content);
        let userPrompt = filterHistory.find((element: any) => ( element.role == 'user' ) );
        let assistantResponse = filterHistory.find((element: any) => ( element.role == "assistant" ) );
        setPromptSent(userPrompt.content);
        setResponse(assistantResponse.content);
        setShowingHistory(true);
        // setChatList(filterHistory);
    };

    useEffect(() => {
        getPageHistory();
    }, []);

    useEffect(() => {
        const newSocket = io(socketUrl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);



    const handleStreamEnd = async (prompt: string, data: ChatType ) => {
        // let dataToSave: ChatType[]  = [
        //     {
        //         role: 'user',
        //         content: prompt,
        //     },
        //     data
        // ]
        let dataToSave = data;

        await handleAddHistory(dataToSave, pageHistory);
        getPageHistory();
    }

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            console.log(data);
            const { streamId, assistant } = data;
            setStreamEndMessage(assistant);
            setShowEditorButton(true);
            setIsTyping(false);
            setCanNavigate(true);

            // handle stream end
            const uuid = uuidv4();
            setDocId(uuid);
            

            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                });
            }
            socket?.emit('get-documents', user?.id);

        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    useEffect(() => {
        
        if ( !isTyping && response != '' ){
            handleStreamEnd(promptSent, streamEndMessage);
        }
    
        let base = prompts.assessment;
        if ( canNavigate && response != '' ){
            openEditor({response, pageId: docId, promptSent: promptSent, promptType: base, navigate, pageSocket: socket,});
        }
    }, [response, canNavigate, isTyping, socket])

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message, type: 'gpt-4' };
        setChats(msgs);
        setNewPromptSent('');
        setResponse('');
        setIsTyping(true);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: prompts.assessment,
                            type: 'gpt-4'
                        },
                        msgs,
                    ],
                },
            });
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    const handleOpenDialog = () => {
        setDialogVisible(true);
    };

    const handleCloseDialog = () => {
        setDialogVisible(false);
    };

    const handleSaveComment = (comment: any) => {
        console.log('Saved comment:', comment);
        handleCloseDialog();
    };

    const onFinish = async (promptMessage: string, combinedPrompt: string) => {
        console.log(promptMessage);
        setPromptSent(combinedPrompt);
        await onGenerateHandler(promptMessage);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const handlePDF = async () => {
        let data = convertStringToDelta(response);
        const blob = await pdfExporter.generatePdf(data)
        const current_time = moment().format('YYYY-MM-DD HH:mm:ss');
        saveAs(blob as Blob, `gmind_document-${current_time}.pdf`);
    }

    const handleWord = async () => {
        let dataToDownload = response;
        const responseData = await axios.post(
            `${baseurl}/document/markdown-to-doc`,
            { content: dataToDownload },
            {},
        );
        const { data: d } = responseData;
        if (d?.statusCode === 1) {
            const link = document.createElement('a');
            link.href = `https://api-v2.gmind.ai/${d?.url}`;
            const current_time = moment().format('YYYY-MM-DD HH:mm:ss');

            link.download = `gmind_document-${current_time}.docx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success('Download successful');
        }
    }

    const handleShare = async () => {
        // const data = Document.current?.data?.ops;
        // const to_markdown = deltaToMarkdown(data);
        

        if (UrlRef.current) {
            setShowShareModal(true);
            return;
        }
        const responseData = await axios.post(
            `${baseurl}/document/markdown-to-doc`,
            { content: response },
            {},
        );

        const { data: d } = responseData;
        if (d?.statusCode === 1) {
            const url = `https://api-v2.gmind.ai/${d?.url}`;
            UrlRef.current = url;
            setShowShareModal(true);
        }
    }   

    const handleShareClick = () => {
        handleShare();
    };

    const handleCopyClick = () => {
        copyData(response);
    };

    const handleMessageClick = () => {
        handleOpenDialog();
    };
    const handleLikeClick = () => {};
    const handleDisLikeClick = () => {};
    const handleDownloadClick = () => setShowDownload(true);

    const promptOptionsData = [
        {
            name: 'Share',
            icon: ShareImage,
            onClick: handleShareClick,
        },
        {
            name: 'Copy',
            icon: Copy,
            onClick: handleCopyClick,
        },
        {
            name: 'Download',
            icon: DownloadImage,
            onClick: handleDownloadClick,
        },
    ];

    const handleDeleteContentHistory = async (id: string) => {
        await handleDeleteHistory(id, pageHistory);
        getPageHistory();
        toast.success("Chat deleted successfully");
    }

    const handleDeleteAllAssesmentHistory = async () => {
        await handleDeleteAllHistory(pageHistory);
        getPageHistory();
        toast.success("Chat cleared successfully");
    }

    return (
        <DashboardFrame
            canNavigateHome={true}
            showSidebar={!isMobile}
            showHistory={!isMobile}
            showTop={!isMobile}
            history={histories}
            selectedHistory={(v: string) => {
                setClickedHistory(v);
            }}
            showPagePath={false}
            onDeleteHistory={(id: string) => {
                handleDeleteContentHistory(id);
            } }
            onDeleteAllHistory={() => handleDeleteAllAssesmentHistory()}
            showHistoryOption={true}
        >
            {!generate && !showingHistory ? (
                <AssessmentHome onSubmit={(prompt, combinedPrompt) => {
                    onFinish(prompt, combinedPrompt);
                }} />
            ) : (
                <div ref={bottomRef} className="w-full flex flex-col gap-0 bg-white h-full overflow-auto">
                        <div className="w-full flex flex-row gap-0 bg-white">
                            <div className="w-full flex flex-col gap-y-3 bg-white px-10 py-10">
                                <div style={{ marginTop: '10px' }}>
                                {
                                    showingHistory && <BackButton
                                        onclick={() => {
                                            setShowingHistory(false)
                                            if ( generate ){
                                                setGenerate(false)
                                            }
                                        }}
                                    />                                
                                }
                                <div className="w-full flex flex-row gap-0 bg-white ">
                                    <div
                                        style={{
                                            backgroundColor: '#F9FAFC',
                                            fontFamily: 'Inter',
                                            fontSize: '0.8125rem',
                                            fontWeight: '400',
                                            paddingRight: '24px',
                                            paddingLeft: '24px',
                                            paddingTop: '16px',
                                            paddingBottom: '16px',
                                        }}
                                        className="mb-10 w-full px-[1.5rem] py-[1rem] flex flex-row gap-10 bg-white "
                                    >
                                        <img src={Profile} alt="profile" />
                                        <span>{promptSent}</span>
                                    </div>
                                </div>
                                    <div className="flex flex-row">
                                       {!<BackButton
                                            onclick={() => {
                                                setGenerate(false);
                                                console.log('hdjdd');
                                            }}
                                        />}
                                        { <ContentTypingComponent isTyping={isTyping} />} 
                                    </div>
                                    
                                    {generating ? (
                                        <LoadingComponent isMobile={false} width={''} />
                                    ) : (
                                        <>
                                            <ResponseTextbox
                                                content={<MarkdownDesign className="">{response}</MarkdownDesign>}
                                                options={ showEditorButton ? promptOptionsData: []}
                                                disLikeClick={handleDisLikeClick}
                                                messageClick={handleMessageClick}
                                                likeClick={handleLikeClick}
                                                profileClick={() => {}}
                                            />
                                        </>
                                    )}
                                </div>``

                                <ReusableDialog
                                    visible={dialogVisible}
                                    title="Add Comment"
                                    inputPlaceholder="Enter your comment"
                                    saveLabel="Save"
                                    onCancel={handleCloseDialog}
                                    onSave={handleSaveComment}
                                />
                                {showShareModal && <Share url={UrlRef.current} onClose={() => setShowShareModal(false)} />}
                                {showDownload && (
                                    <Download
                                        handlePDF={handlePDF}
                                        handleWord={handleWord}
                                        url={UrlRef.current}
                                        onClose={() => setShowDownload(false)}
                                    />
                                )}
                            </div>
                        </div>
                </div>
            )}
        </DashboardFrame>
    );
};

export default Assessment;
