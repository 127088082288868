export function checkDaysBefore(date:string) {
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
    const differenceInTime = now.getTime() - new Date(date).getTime();
    const daysDifference = Math.floor(differenceInTime / oneDay);
  
    switch (daysDifference) {
      case 1:
        return "1 day ago";
      case 2:
        return "2 days ago";
      case 7:
        return "A week ago";
      case 14:
        return "2 weeks ago";
      case 21:
        return "3 weeks ago";
      case 30:
      case 31: // Handles February or months with 31 days
        return "A month ago";
      default:
        return `${daysDifference} days ago`;
    }
  }
  
  
  