/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import letter from '@assets/gmind_letter.svg';
import avatar from '@assets/chat/user.svg';
import check from '@assets/check_green.svg';
import copy from '@assets/chat/copy.svg';
import markdownToTxt from 'markdown-to-txt';
import regenerate from '@assets/chat/repeat.svg';
import markdown from "@wcj/markdown-to-html"
import back from '@assets/chat/back_circle.svg';
import { Share } from '@src/pages/dashboard/components/Share';
import Skeleton from './skeleton';
import share from '@public/share.svg';
import Copy from '@public/copy.svg';
import download from '@public/download.svg';
import './style.css';
import { Download } from '@src/pages/dashboard/components/Share/Download';
import { notification } from 'antd';
import Markdown from '@components/Markdown/index';
import { toast } from 'react-toastify';
import axios from 'axios';
import { pdfExporter } from 'quill-to-pdf';
import { getUrlPath } from '@src/utils/app_functions';
import ContentTypingComponent from './content_typing';
import { ChatContent, Chats, isChatContentArray } from './interface/interface';
import { BASE_URL } from '@src/utils/constants';
import { marked } from 'marked';
import { convertHTMLToPDF, convertHTMLToWord } from '../pages/essay/functions';
import { useUserContext } from '@src/context/UseProvider';


export const ChatScreen = ({
    chatList,
    showNavBar,
    isMobile,
    streaming,
    backClick,
    actions,
    moreActions,
    typing,
}: {
    chatList: Chats[];
    showNavBar: boolean;
    streaming: boolean;
    isMobile?: boolean;
    backClick: () => void;
    actions?: (v: string, i: number) => void;
    moreActions?: [{ icon: string; action: string }];
    typing?: boolean;
}) => {
    const containerRef = useRef<any>();
    const Document = useRef<any>();
    const [page, setPage] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const pageDetails = getUrlPath();
        setPage(pageDetails.pagename);
    }, [window.location.pathname]);

    useEffect(() => {
        const container: HTMLDivElement = containerRef.current;
        if (container) {
            container.scrollTop = container.scrollHeight - container.clientHeight;
        }
    }, [chatList, streaming]);

    function handleAction(action: string, index: number) {
        actions && actions(action, index);
    }



    return (
        <section className="w-full overflow-auto">
            {showNavBar && (
                <div className="w-full bg-white z-[100] sticky top-0 h-12 flex items-center justify-between border-b mb-6 border-gray-300 px-10">
                    <div className="flex items-center gap-3">
                        <img onClick={backClick} className="w-6" role="button" src={back} alt="" />
                        {/* {page != 'chat' && (
                            <div>
                                <p className="text-sm">Chat</p>
                                <img className="w-4" role="button" src={edit} alt="" />
                            </div>
                        )} */}
                    {/* { typing && <ContentTypingComponent isTyping={ typing ?? false } /> } */}

                    </div>
                    <div className="flex items-center gap-3">
                        {/* <div role="button" className="bg-[#f9fafc] p-1 px-3 rounded-xl flex items-center gap-2">
                            <img src={Profile} alt="" className="w-3" />
                            <p className="text-[0.9rem] font-medium">Humanize</p>
                        </div> */}
                    </div>
                </div>
            )}
            <div
                ref={containerRef}
                className="px-10 pb-5 flex flex-col mb-5 gap-0"
                style={{
                    paddingTop: !showNavBar ? '1.5rem' : '',
                    padding: isMobile ? '0' : '',
                }}
            >
                { typing && <ContentTypingComponent isTyping={ typing ?? false } /> }

                {chatList.map(({ role, content }, i) => (
                    <Bubble
                        moreActions={moreActions}
                        action={handleAction}
                        index={i}
                        key={i}
                        
                        isMobile={isMobile}
                        role={role}
                        style={isMobile ? { width: 'calc(100% - 2rem)', margin: '0 auto' } : {}}
                        content={content}
                        typing={ i != 0 ? typing: undefined }
                    />
                ))}
                {chatList?.length && chatList[chatList.length - 1].role === 'user' && !streaming ? <Skeleton  /> : ''}
            </div>
        </section>
    );
};

const Bubble = ({
    role,
    icon = avatar,
    className,
    style,
    isMobile,
    content,
    index,
    action,
    moreActions,
    typing,
}: {
    role: string;
    icon?: string;
    className?: string;
    isMobile?: boolean;
    style?: React.CSSProperties;
    content: ChatContent[] | string;
    index: number;
    action: (v: string, i: number) => void;
    moreActions?: [{ icon: string; action: string }];
    typing?: boolean
}) => {
    const containerRef = useRef<any>();
    const UrlRef = useRef<string>('');
    const [copied, setCopied] = useState(false);
    const [liked, setLike] = useState(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [disliked, setDisLike] = useState(false);
    const [openDownload, setOpenDownload] = useState<boolean>(false);
    const [pdfDownloading, setPdfDownloading ] = useState(false);
    const [profilePic, setProfilePic] = useState<string>('');
    const { userDetails } = useUserContext();


    function handleNewFeatures() {
        notification.info({ message: 'Please be patient as this feature is still in development.' });
    }

    useEffect(() => {
        const container: HTMLParagraphElement = containerRef.current;
        if (container) {
            container.scrollTop = container.scrollHeight - container.clientHeight;
        }
    }, [content]);

    useEffect(() => {
        if (!copied) return;

        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }, [copied]);

    async function handleShare() {
        let contentValue = typeof content === 'string' ? content : (
            isChatContentArray(content) ? content?.length > 0 ? content[0]?.text ?? '' : '' : ''
        )

        const baseurl = BASE_URL;
        console.log('share');
        const to_markdown = markdownToTxt(contentValue);

        if (UrlRef.current) {
            setShowShareModal(true);
            return;
        }
        try {
            const response = await axios.post(
                `${baseurl}/document/markdown-to-doc`,
                { content: to_markdown },
                {},
            );
            const { data: d } = response;
            if (d?.statusCode !== 1) {
                toast.error(d?.message ?? 'Something went wrong while generating share link');
                return;
            }

            const newUrl = baseurl.replace('v1', '');

            const url = `${newUrl}${d?.url}`;
            UrlRef.current = url;
            setShowShareModal(true);
        } catch (error: any) {
            console.log(error, 'e');
            const server_message = error?.response?.data?.message;
            toast.error(server_message ?? 'Something went wrong.');
        }
    }


    async function handlePDF() {
        // eslint-disable-next-line prefer-const
        let contentValue = typeof content === 'string' ? content : (
            isChatContentArray(content) ? content?.length > 0 ? content[0]?.text ?? '' : '' : ''
        )
        let data = await marked( contentValue );
        try {
            setPdfDownloading(true);
            await convertHTMLToPDF(data);
        } catch (error) {
            toast.error("Error downloading documents");
        }finally {
            setPdfDownloading(false);
        }
    }

    async function handleWord() {
        let contentValue = typeof content === 'string' ? content : (
            isChatContentArray(content) ? content?.length > 0 ? content[0]?.text ?? '' : '' : ''
        )
        const to_markdown = markdownToTxt(contentValue);
        const to_html = markdown(content);
        console.log(to_html.trim(), "html");

        convertHTMLToWord(to_html);
        // const response = await axios.post(
        //     `${BASE_URL.slice(0, -3)}/html-to-doc`,
        //     { content: to_html?.trim() },
        //     {},
        // );
        // const { data: d } = response;
        // if (d?.statusCode === 1) {
        //     console.log(d, "url")
        //     const link = document.createElement('a');

        //     link.download = `gmind_document-${moment().format("YYYY-MM-DD-HH-mm:ss A")}.docx`;
        //     link.href = `${BASE_URL.slice(0, -3)}/${d?.url}`;
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        //     toast.success('Download successful');
        // }
    }

    function handleRegenerate() {
        action('regenerate', index);
    }


    // const onRefreshPageDetails = async () => {
    //     const token = localStorage.getItem('token');
    //     if (!token) return;
    //     const details = await GetUser(token);
    //     const detailsFetched: UserDetailsData = details.data;
    //     onFetchDetails(detailsFetched);
    // };
    // useEffect(() => {
    //     if (!profilePic) {
    //         // Call the function if the profile picture is empty
    //         onRefreshPageDetails();
    //     }
    // }, [profilePic]);

    // const onFetchDetails = (details: UserDetailsData) => {

    //     if (details.profilePic === null) {
    //         setProfilePic("");
    //     } else {
    //         setProfilePic(details?.profilePic);
    //     }

      
    // };



    function handleCopyText() {
        let contentValue = typeof content === 'string' ? content : (
            isChatContentArray(content) ? content?.length > 0 ? content[0]?.text ?? '' : '' : ''
        )
        navigator.clipboard
            .writeText(contentValue)
            .then(() => {
                console.log('Written');
                toast.success('Content copied to clipboard!');

                setCopied(true);
            })
            .catch((err) => {
                console.log('Failed, ', err);
            });
    }

    return (
        <div
            className={`flex items-center gap-3 py-2 rounded-lg w-full pl-5 md:pl-[17%] pr-5 md:pr-[17%] ` + className}
            style={
                role === 'assistant'
                    ? { background: 'white', gap: 0, flexDirection: 'column', alignItems: 'start', ...style }
                    : { flexDirection: 'column', gap: 0, alignItems: 'start', ...style }
            }
        >
            {showShareModal && (
                <Share
                    onClose={() => {
                        setShowShareModal(false);
                    }}
                    url={UrlRef.current}
                />
            )}
            {openDownload && (
                <Download
                    url=""
                    handlePDF={handlePDF}
                    handleWord={handleWord}
                    isPdfDownloading={pdfDownloading}
                    onClose={() => {
                        setOpenDownload(false);
                    }}
                />
            )}
            <div
                style={
                    !isMobile
                        ? { display: 'block', width: 'fit-content' }
                        : { display: 'flex', alignItems: 'center', width: role === 'user' ? 'fit-content' : '100%' }
                }
                className=""
            >
                <div className="flex gap-x-3 items-center">
                    <img
                        src={role === 'user' ? userDetails?.profilePic !== null ? userDetails?.profilePic: icon : letter}
                        alt={role}
                        className={`${role === 'assistant' ? 'w-7 scale-[1.3]' : userDetails?.profilePic !== null ? "w-7 h-7 bg-cover bg-center border border-black bg-[#001f73] rounded-full": 'w-6 border border-black bg-[#001f73] rounded-full p-[0.2rem]'}`}
                    />
                    <p className='font-normal font-optimistic text-sm text-black '>{role === 'user' ? 'You': 'Gmind AI'}</p>
                </div>
                

                {role === 'assistant' && isMobile && (
                    <div className="flex items-center ms-auto gap-8">
                        <img role="button" onClick={() => {}} className="w-5" src={share} alt="" />
                        <img
                            role="button"
                            onClick={() => {
                                handleCopyText();
                            }}
                            className="w-5"
                            src={copied ? check : Copy}
                            alt=""
                        />
                        <img role="button" onClick={() => {}} className="w-5" src={download} alt="" />
                    </div>
                )}
            </div>

            {
            <div className="w-full content_holder">
                {/* image section */}
                {
                   isChatContentArray(content) && content?.length >= 2  && (
                        <div className="flex flex-col w-full h-auto max-h-40">
                            <img className='mt-5 w-40' src={content[1]?.image_url?.url ?? ''} alt="chat image" loading='lazy' />
                        </div>
                   ) 
                }
                <Markdown className="text-left markdown me-auto leading-6 flex flex-col items-start gap-3 text-[0.94rem] h-auto overflow-auto content w-full">
                    {
                     typeof content === 'string' ? content : (
                            isChatContentArray(content) ? content?.length > 0 ? content[0]?.text ?? '' : '' : ''
                        )
                    }
                </Markdown>
                {role === 'assistant' ? (
                    <section className="flex justify-between items-center w-full mb-[15px]">
                        {!isMobile && (
                            <div className="flex items-center gap-3 mt-3">
                                <div
                                    onClick={handleShare}
                                    role="button"
                                    className="bg-[#f9fafc] p-1 px-3 rounded-xl flex items-center gap-2"
                                >
                                    <img src={share} alt="" className="w-[1rem]" />
                                    <p className="text-[0.9rem] font-medium">Share</p>
                                </div>
                                <div
                                    onClick={handleCopyText}
                                    role="button"
                                    className="bg-[#f9fafc] p-1 px-3 rounded-xl flex items-center gap-2"
                                >
                                    <img src={copied ? check : copy} alt="" className="w-[1rem]" />
                                    <p className="text-[0.9rem] font-medium">{copied ? 'Copied!' : 'Copy'}</p>
                                </div>
                                <div
                                    onClick={() => setOpenDownload(true)}
                                    role="button"
                                    className="bg-[#f9fafc] p-1 px-3 rounded-xl flex items-center gap-2"
                                >
                                    <img src={download} alt="" className="w-[1rem]" />
                                    <p className="text-[0.9rem] font-medium">Download</p>
                                </div>
                                {moreActions?.map(({ action: _action, icon }) => (
                                    <div
                                        onClick={() => action(_action, index)}
                                        role="button"
                                        className="bg-[#f9fafc] p-1 px-3 rounded-xl flex items-center gap-2"
                                    >
                                        <img src={icon} alt="" className="w-4" />
                                        <p className="text-[0.9rem] font-medium">{_action}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div
                            style={isMobile ? { flexDirection: 'row-reverse', width: '100%' } : {}}
                            className="flex items-center justify-between gap-10"
                        >
                            <button onClick={() => handleRegenerate()} className="flex items-center gap-2">
                                <img src={regenerate} alt="" className="w-4" />
                                <p className="text-[0.9rem]">Regenerate</p>
                            </button>

                        </div>
                    </section>
                ) : (
                    ''
                )}
            </div> }
        </div>
    );
};
