import React from 'react';
import { Table, Button, Tag } from 'antd';
import './TeacherTable.scss';

interface Teacher {
    key: string;
    id: string;
    name: string;
    email: string;
    status: string;
}
interface TeacherTableProps {
    teachers: Teacher[];
}

const TeacherTable = ({ teachers }: TeacherTableProps) => {
    const columns = [
        {
            title: 'Teacher ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name of Teachers',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => (
                <Tag style={{ border: 'none' }} color={status === 'active' ? 'green' : 'red'}>
                    {status === 'active' ? 'Active' : 'Inactive'}
                </Tag>
            ),
        },
        {
            title: <p className="flex flex-row justify-center"> Actions</p>,
            key: 'action',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render: (_: any, record: any) => (
                <div className=" action-button flex  gap-3 md:gap-0 flex-col md:flex-row items:center justify-center">
                    <Button style={{ backgroundColor: '#B031D0', color: '#fff', marginRight: '8px', gap: '1px', padding:"6px" }}>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                            <rect width="20" height="20" fill="url(#pattern0_2531_65846)" />
                            <defs>
                                <pattern
                                    id="pattern0_2531_65846"
                                    patternContentUnits="objectBoundingBox"
                                    width="1"
                                    height="1"
                                >
                                    <use xlinkHref="#image0_2531_65846" transform="scale(0.0111111)" />
                                </pattern>
                                <image
                                    id="image0_2531_65846"
                                    width="90"
                                    height="90"
                                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPklEQVR4nO3dvYrUUByG8VjozayKYCVaiHbiBXgPdorYCDOdIihYiKWKH421na2l3oEgqxZ+NJZ+wCMHZ4uFuGYmyZvNnufpZ+fkt2cy/81smKYxMzMzMzOz/RtwAngCfAJ+Ah+BR8DRqdd2YAKuAr9p7xdweeo1zj5gSbduTL3WGpB3EjuALHYQWewgsthBZLGDyDs5+oWgy5y9tevJao/xsB9OfWy1YG9PfVy1YP+Y+phqwf4w9fHUgv24qRBuucFj+lSu+h1vaondYEnsK00t0Q51a82fcX0D5EVTS+y9G8fEFjmALXIAW+QAtsgBbJHXbJPRb63HzDqG/ZN5rZ1dTYxzxU3s0DVksUPIYgeR68Ymi1wnNtMgl5yTA4kcSORAIgcSOZDIgUQOJHIgkQOJHEjkQCIHEjmQyIFEDiRyIJFFHjA/GQkkciDgMHASOA9cKzfOJM4V1PRvWm0Bp0TO7fBy2647eeyAbyMpL0Zf/JwC3okcCHgzMPQyse7ZBbwaEHkx9fHs24AXIgcC7gwAvUysddYB50TuGXCsy24DzgK3gWfAS+A18HY1kew1/rmTV4A3hwABtoDvIrfjHALeD7X7gLvu5HaYM0O+1IFLni7aYR4MeVsCf6/4OSe3XCz6OuSbGHBkk1/QgQ642GFkq+uGmzECnneAFrtvwJeO0GL3afUlA/9rG7hXPtrq9WQ1V86//8Atb5D3gdNlzp56nbOvTAgr7LKzPwNPgQtlGpl6bWZmZmZmZtb06w8+ceqv9e1skAAAAABJRU5ErkJggg=="
                                />
                            </defs>
                        </svg>
                        Edit
                    </Button>
                    <Button style={{ backgroundColor: '#9E1313', color: '#fff', marginRight: '8px',gap: '1px', padding:"6px" }}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.0026 18.3359C4.54427 18.3359 4.15205 18.1729 3.82594 17.8468C3.49983 17.5207 3.33649 17.1282 3.33594 16.6693V8.33594C3.33594 7.87761 3.49927 7.48538 3.82594 7.15927C4.1526 6.83316 4.54483 6.66983 5.0026 6.66927H5.83594V5.0026C5.83594 3.84983 6.24233 2.86733 7.0551 2.0551C7.86788 1.24288 8.85038 0.836494 10.0026 0.835938C11.1548 0.835383 12.1376 1.24177 12.9509 2.0551C13.7643 2.86844 14.1704 3.85094 14.1693 5.0026V6.66927H15.0026C15.4609 6.66927 15.8534 6.8326 16.1801 7.15927C16.5068 7.48594 16.6698 7.87816 16.6693 8.33594V16.6693C16.6693 17.1276 16.5062 17.5201 16.1801 17.8468C15.854 18.1734 15.4615 18.3365 15.0026 18.3359H5.0026ZM10.0026 14.1693C10.4609 14.1693 10.8534 14.0062 11.1801 13.6801C11.5068 13.354 11.6698 12.9615 11.6693 12.5026C11.6687 12.0437 11.5057 11.6515 11.1801 11.3259C10.8545 11.0004 10.462 10.837 10.0026 10.8359C9.54316 10.8348 9.15094 10.9982 8.82594 11.3259C8.50094 11.6537 8.3376 12.0459 8.33594 12.5026C8.33427 12.9593 8.4976 13.3518 8.82594 13.6801C9.15427 14.0084 9.54649 14.1715 10.0026 14.1693ZM7.5026 6.66927H12.5026V5.0026C12.5026 4.30816 12.2595 3.71788 11.7734 3.23177C11.2873 2.74566 10.697 2.5026 10.0026 2.5026C9.30816 2.5026 8.71788 2.74566 8.23177 3.23177C7.74566 3.71788 7.5026 4.30816 7.5026 5.0026V6.66927Z"
                                fill="white"
                            />
                        </svg>
                        Restrict
                    </Button>
                    <Button style={{ backgroundColor: '#FE0303', color: '#fff', gap: '1px', padding:"6px" }}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.83594 17.5C5.3776 17.5 4.98538 17.3369 4.65927 17.0108C4.33316 16.6847 4.16983 16.2922 4.16927 15.8333V5H3.33594V3.33333H7.5026V2.5H12.5026V3.33333H16.6693V5H15.8359V15.8333C15.8359 16.2917 15.6729 16.6842 15.3468 17.0108C15.0207 17.3375 14.6282 17.5006 14.1693 17.5H5.83594ZM14.1693 5H5.83594V15.8333H14.1693V5ZM7.5026 14.1667H9.16927V6.66667H7.5026V14.1667ZM10.8359 14.1667H12.5026V6.66667H10.8359V14.1667Z"
                                fill="white"
                            />
                        </svg>
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    return <Table dataSource={teachers} columns={columns} pagination={{ pageSize: 5 }} className="custom-table" />;
};

export default TeacherTable;
