interface DataType<T>  {
    title: T;
    content: T;
    id: T;
    date?: T;
};


type Chats = { 
    role: string; 
    content: ChatContent[] | string;
    type?: string 
};


export type ChatContent = {
    type:  'text' | 'image_url';
    text?: string;
    image_url?: ImageURL;
}

export type ImageURL = {
    url: string;
}

export type {
    DataType,
    Chats
}

export interface PictureUploadResponse {
    success: boolean;
    message: string;
    url?: string;
}


export const isChatContentArray = (variable: any): variable is ChatContent[] => {
    return Array.isArray(variable) && variable.every(item => 
        typeof item === 'object' &&
        item !== null &&
        typeof item.type === 'string' &&
        (item.text === undefined || typeof item.text === 'string') &&
        (item.image_url === undefined ||
         (typeof item.image_url === 'object' &&
          item.image_url !== null &&
          typeof item.image_url.url === 'string'))
    );
}