import { Spin } from 'antd';
import './loading.scss'; // Assuming you have a CSS/SCSS file for custom styles

const EditorLoading = () => {
    return (
        <div className="loading-container">
            <div className="loading-content">
                <p className="loading-text">Loading your Editor...</p>
                <Spin size="default" className="loading-spinner"  style={{color:'#ed5405'}}/>
            </div>
        </div>
    );
};

export default EditorLoading;
