/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import DashboardFrame from '@src/components/DashboardFrame';

import SchoolCard from '../components/school_card';
import { checkDaysBefore } from '../function/helper';
import TeacherTable from '../components/teacher_table';
import { useNavigate } from 'react-router';

interface SchoolCardProps {
    teachers: string;
    duration: string;
    total_teachers: string;
    percentage: string;
}

const SchoolAdmin = () => {
    const [histories, setHistories] = useState<any[]>([]);
    const [isMobile, setIsMobile] = useState(true);
    const [name, setName] = useState('Oluseye');
    const navigate = useNavigate();

    const dataSource = [
        {
            key: '1',
            id: 'T001',
            name: 'John Doe',
            email: 'john.doe@example.com',
            status: 'active',
        },
        {
            key: '2',
            id: 'T002',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
            status: 'inactive',
        },
    ];

    const TeacherList: SchoolCardProps[] = [
        {
            teachers: 'Total Teachers',
            duration: '2024-09-15',
            total_teachers: '100',
            percentage: '3.4',
        },
        {
            teachers: 'Active Teacher',
            duration: '2024-09-15',
            total_teachers: '100',
            percentage: '3.4',
        },
        {
            teachers: 'Inactive Teachers',
            duration: '2024-09-15',
            total_teachers: '100',
            percentage: '3.4',
        },
        {
            teachers: 'Restricted Teachers',
            duration: '2024-09-15',
            total_teachers: '100',
            percentage: '3.4',
        },
    ];

    function handleOpenEditor(id?: string) {
        const filterHistory = histories
            .filter((h: any) => {
                const { id: history_id } = h[h.length - 1];
                return String(history_id) === String(id);
            })
            .flat()
            .filter((h: any) => !h?.id);

        console.log(filterHistory);
    }

    return (
        <DashboardFrame
            showHistory={!isMobile}
            showSidebar={isMobile}
            schoolAdminPageName='Dashboard'
            showRightComponent={false}
            mobileNavStyle={isMobile ? { position: 'fixed' } : {}}
            history={histories}
            showTop={true}
            showSchoolHeader={true}
            selectedHistory={(v) => {
                handleOpenEditor(v);
            }}
            showInput
            showPagePath={false}
            onDeleteHistory={(id: string) => {}}
            canNavigateHome={true}
        >
            <div className="bg-[#F9FAFC] w-full h-full pr-[36px] pl-[36px] pt-[32px] ">
                <div className="flex flex-row gap-1">
                    <InfoIcon />

                    <span className="text-[#FF6600] text-[18px] font-medium leading-7 text-left font-Montserrat">
                        Hi {name} 👋. Welcome back.
                    </span>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 pt-[24px] ">
                    {TeacherList.map((teacher, index) => (
                        <SchoolCard
                            key={index}
                            teachers={teacher.teachers}
                            duration={checkDaysBefore(teacher.duration)}
                            total_teachers={teacher.total_teachers}
                            percentage={teacher.percentage}
                        />
                    ))}
                </div>

                <div className="flex flex-row items-center justify-between pt-[32px] ">
                    <span className="font-Poppins text-xl font-medium leading-7 text-left text-black">
                        Recent Teachers
                    </span>
                    <span onClick={()=> navigate("school-teacher")} className="font-Poppins text-base font-normal leading-6 text-left text-customOrange cursor-pointer">
                        {' '}
                        See all
                    </span>
                </div>

                <div className="w-full h-full bg-white p-[24px] mt-[8px] overflow-x-scroll">
                    <TeacherTable teachers={dataSource} />
                </div>
            </div>
        </DashboardFrame>
    );
};

export default SchoolAdmin;

const InfoIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
            <path
                d="M21 14C21 15.8565 20.2625 17.637 18.9497 18.9497C17.637 20.2625 15.8565 21 14 21C12.1435 21 10.363 20.2625 9.05025 18.9497C7.7375 17.637 7 15.8565 7 14C7 12.1435 7.7375 10.363 9.05025 9.05025C10.363 7.7375 12.1435 7 14 7C15.8565 7 17.637 7.7375 18.9497 9.05025C20.2625 10.363 21 12.1435 21 14Z"
                fill="#FF6600"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0026 1.46094C14.2347 1.46094 14.4572 1.55312 14.6213 1.71722C14.7854 1.88131 14.8776 2.10387 14.8776 2.33594V3.5026C14.8776 3.73467 14.7854 3.95723 14.6213 4.12132C14.4572 4.28542 14.2347 4.3776 14.0026 4.3776C13.7705 4.3776 13.548 4.28542 13.3839 4.12132C13.2198 3.95723 13.1276 3.73467 13.1276 3.5026V2.33594C13.1276 2.10387 13.2198 1.88131 13.3839 1.71722C13.548 1.55312 13.7705 1.46094 14.0026 1.46094ZM1.46094 14.0026C1.46094 13.7705 1.55312 13.548 1.71722 13.3839C1.88131 13.2198 2.10387 13.1276 2.33594 13.1276H3.5026C3.73467 13.1276 3.95723 13.2198 4.12132 13.3839C4.28542 13.548 4.3776 13.7705 4.3776 14.0026C4.3776 14.2347 4.28542 14.4572 4.12132 14.6213C3.95723 14.7854 3.73467 14.8776 3.5026 14.8776H2.33594C2.10387 14.8776 1.88131 14.7854 1.71722 14.6213C1.55312 14.4572 1.46094 14.2347 1.46094 14.0026ZM23.6276 14.0026C23.6276 13.7705 23.7198 13.548 23.8839 13.3839C24.048 13.2198 24.2705 13.1276 24.5026 13.1276H25.6693C25.9013 13.1276 26.1239 13.2198 26.288 13.3839C26.4521 13.548 26.5443 13.7705 26.5443 14.0026C26.5443 14.2347 26.4521 14.4572 26.288 14.6213C26.1239 14.7854 25.9013 14.8776 25.6693 14.8776H24.5026C24.2705 14.8776 24.048 14.7854 23.8839 14.6213C23.7198 14.4572 23.6276 14.2347 23.6276 14.0026ZM14.0026 23.6276C14.2347 23.6276 14.4572 23.7198 14.6213 23.8839C14.7854 24.048 14.8776 24.2705 14.8776 24.5026V25.6693C14.8776 25.9013 14.7854 26.1239 14.6213 26.288C14.4572 26.4521 14.2347 26.5443 14.0026 26.5443C13.7705 26.5443 13.548 26.4521 13.3839 26.288C13.2198 26.1239 13.1276 25.9013 13.1276 25.6693V24.5026C13.1276 24.2705 13.2198 24.048 13.3839 23.8839C13.548 23.7198 13.7705 23.6276 14.0026 23.6276Z"
                fill="#FF6600"
            />
            <path
                opacity="0.5"
                d="M5.12889 5.13148C5.21015 5.05017 5.30663 4.98567 5.41283 4.94166C5.51902 4.89765 5.63285 4.875 5.74781 4.875C5.86276 4.875 5.97659 4.89765 6.08278 4.94166C6.18898 4.98567 6.28546 5.05017 6.36672 5.13148L6.82522 5.58998C6.97978 5.75585 7.06393 5.97524 7.05993 6.20192C7.05593 6.42861 6.9641 6.64489 6.80378 6.8052C6.64347 6.96552 6.42718 7.05735 6.2005 7.06135C5.97381 7.06535 5.75443 6.98121 5.58856 6.82665L5.12889 6.36931C4.96503 6.20525 4.87299 5.98286 4.87299 5.75098C4.87299 5.5191 4.96503 5.29671 5.12889 5.13265V5.13148ZM22.8646 5.13265C23.0284 5.29671 23.1205 5.5191 23.1205 5.75098C23.1205 5.98286 23.0284 6.20525 22.8646 6.36931L22.4072 6.82781C22.2414 6.98237 22.022 7.06652 21.7953 7.06252C21.5686 7.05852 21.3523 6.96669 21.192 6.80637C21.0317 6.64606 20.9399 6.42977 20.9359 6.20309C20.9319 5.9764 21.016 5.75702 21.1706 5.59115L21.6279 5.13265C21.792 4.96879 22.0143 4.87676 22.2446 4.87676C22.4749 4.87676 22.6972 4.96879 22.8646 5.13265V5.13265Z"
                fill="#FF6600"
            />
        </g>
    </svg>
);
