import React from 'react';
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

interface LoginPromptModalProps {
    visible: boolean;
}

const LoginPromptModal: React.FC<LoginPromptModalProps> = ({ visible }) => {
    const navigate = useNavigate();

    const handleLoginRedirect = () => {
        const pathUrl = window.location;
        console.log(pathUrl);
        navigate(`/login?callback=${pathUrl}`); // Adjust the path if your login route is different
    };

    return (
        <Modal
            title={<span className="text-xl font-semibold">Login Required</span>}
            open={visible}
            centered
            closable
            maskClosable={false} // Prevent closing by clicking outside
            keyboard={false}
            footer={null} // Disable default footer
            closeIcon={<div></div>}
            width={600}
        >
            <p className="text-lg font-medium">You need to be logged in to access this feature.</p>
            <div className="flex justify-end space-x-2 mt-4">
                <Button
                    className="text-lg font-medium bg-[#ed5405] text-white hover:bg-[#d94c03]"
                    onClick={handleLoginRedirect}
                >
                    Go to Login
                </Button>
            </div>
        </Modal>
    );
};
export default LoginPromptModal;
