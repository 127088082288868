/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import History from '@src/pages/dashboard/components/history';
import Sidebar from './Sidebar';
import TopNavBar from './Top';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import RightComponent from '@src/pages/dashboard/components/rightComponent';
import MobileNavbar from '@src/pages/dashboard/components/mobileNavBar';
import HistoryModal from '@src/pages/dashboard/components/historyModal';
import ArticleModal from '@src/pages/dashboard/components/ArticleModal';
import { GetUser } from '@src/core/endpoints/user';
import { PlanDetails, UserDetailsData } from '@src/core/interfaces/user';
import { plans } from '@src/pages/acquisition/pricing/constants';
import { getUrlPath } from '@src/utils/app_functions';
import { checkIfTimeIsWithinRange, onTrialReminder } from '@src/pages/dashboard/pages/essay/functions';
import { HistoryTypeFormat } from '@src/core/interfaces/chat';
import ExpiredPackageModal from './expired_plan';
import { toast } from 'react-toastify';
import SchoolHeader from '@src/pages/dashboard/pages/school_admin/components/header';

type ChatType = {
    role: 'assistant' | 'user';
    content: string;
    id: string;
};

type DataType<T> = {
    title: T;
    content: T;
    id: T;
};

const DashboardFrame = ({
    schoolAdminPageName,
    showHistory = true,
    showMobileHistory = true,
    showTop,
    showSidebar = true,
    showRightComponent = false,
    rightComponentName,
    rightComponentPlaceholder,
    rightComponentClick,
    rightComponentData,
    history,
    middleClass,
    mobileNavStyle,
    selectedHistory,
    children,
    topExtraData,
    closeIdValue,
    showHistoryOption,
    onClickPage,
    onCloseHistory,
    historyVisible,
    showPagePath = true,
    gmindTyping,
    onDeleteHistory,
    onDeleteAllHistory,
    canNavigateHome = true,
    autoScroll,
    showExpiredModal = true,
    onDetailsFetched,
    showSchoolHeader,
    showInput,
}: {
    schoolAdminPageName?: string;

    showHistory?: boolean;
    showMobileHistory?: boolean;
    showTop?: boolean;
    showSidebar?: boolean;
    showRightComponent?: boolean;
    rightComponentName?: string;
    rightComponentPlaceholder?: string;
    middleClass?: string;
    rightComponentClick?: (id: string) => void;
    rightComponentData?: DataType<string>[];
    history?: HistoryTypeFormat[];
    mobileNavStyle?: React.CSSProperties;
    selectedHistory: (v: string) => void;
    children: any;
    topExtraData?: ReactNode;
    closeIdValue?: string;
    showHistoryOption?: boolean;
    onClickPage?: () => void;
    onCloseHistory?: () => void;
    historyVisible?: boolean;
    showPagePath?: boolean;
    gmindTyping?: boolean;
    showSchoolHeader?: boolean;
    onDeleteHistory: (id: string) => void;
    onDeleteAllHistory?: () => void;
    canNavigateHome: boolean;
    autoScroll?: boolean;
    showExpiredModal?: boolean;
    onDetailsFetched?: (e: UserDetailsData) => void;
    showInput?: boolean;
}) => {
    const [sidebarCollapse, setSidebarCollapse] = useState(false);
    const [historyCollapse, setHistoryCollapse] = useState<boolean>(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [historyHidden, setHistoryHidden] = useState(false);
    const [selected, setSelected] = useState<string | undefined>();
    const [showMobileElements, setShowMobileElements] = useState({ showSidebar: false, showHistory: false });
    const [isMobile, setIsMobile] = useState(true);
    const [userPlan, setUserPlan] = useState<PlanDetails | undefined>(undefined);

    const [PathName, setPathName] = useState(window.location.pathname);
    const [packageName, setPackageName] = useState<string | undefined>('');
    const [isExpiredVisible, setIsExpiredVisible] = useState<boolean>(false);
    const [profilePic, setProfilePic] = useState('');
    const navigate = useNavigate();
    const [details, setDetails] = useState<UserDetailsData>();

    const getDetails = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            const details = await GetUser(token);
            if (details.statusCode == 1) {
                const { id, firstName, lastName, email, isVerified, plan, freeTrialAvailable, daysRemaining } =
                    details.data;
                const detailsFetched: UserDetailsData = details.data;
                setDetails(details.data);
                if (onDetailsFetched) onDetailsFetched(detailsFetched);
                localStorage.setItem('user', JSON.stringify(detailsFetched));

                if ((!plan || !plan?.details) && !freeTrialAvailable) {
                    navigate(`/dashboard/pricing`);
                }

                if ((!plan || !plan?.details) && freeTrialAvailable) {
                    const lastNotified = localStorage.getItem('lastNotified');
                    if (lastNotified) {
                        // check if greater tha 3o minutes
                        if (checkIfTimeIsWithinRange(30, lastNotified)) {
                            localStorage.setItem('lastNotified', Date.now().toString());
                            const goToPricing = () => navigate(`/dashboard/pricing`);
                            onTrialReminder(daysRemaining, goToPricing);
                        }
                    } else {
                        localStorage.setItem('lastNotified', Date.now().toString());
                        const goToPricing = () => navigate(`/dashboard/pricing`);
                        onTrialReminder(daysRemaining, goToPricing);
                    }
                }

                setPackageName(plan?.details?.name);
                setUserPlan(plan);

                if (plan.expired) {
                    setIsExpiredVisible(true);
                }
            } else if (details.statusCode == 2 || details.statusCode == 401) {
                localStorage.removeItem('token');
                toast.error(details.statusCode == 2 ? 'User unauthorized' : 'User already logged in on another device');
                window.location.pathname = '/';
                return;
            }

            console.log(details);
        } else {
            // eslint-disable-next-line prefer-const
            let path = getUrlPath();
            if (path.folder == 'dashboard') {
                window.location.href = '/';
            }
        }
    };

    // get user details on each page moved to
    useEffect(() => {
        getDetails();
    }, [window.location.pathname]);

    useEffect(() => {
        if (selected) {
            setSelected('');
            navigate(`/dashboard/${selected}`);
        }
    }, [selected]);

    useEffect(() => {
        const { pathname } = window.location;
        const split = pathname.split('/');
        const last = split[split.length - 1];

        setPathName(last);
    }, [window.location.pathname]);

    useEffect(() => {
        if (showRightComponent && !isMobile) {
            showHistory && (showHistory = false);
        }
        if (showHistory && !isMobile) {
            showRightComponent && (showRightComponent = false);
        }
    }, [showRightComponent, showHistory]);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    return (
        <main
            style={isMobile ? { flexDirection: 'column' } : {}}
            className="flex h-full w-screen max-w-[100rem] mx-auto"
        >
            {isMobile && (
                <MobileNavbar
                    style={mobileNavStyle}
                    showMobileHistory={showMobileHistory}
                    showSidebar={() => {
                        setShowMobileElements((c) => ({ ...c, showSidebar: !c.showSidebar }));
                        if (showMobileElements.showHistory) {
                            setShowMobileElements((c) => ({ ...c, showHistory: false }));
                        }
                    }}
                    className="sticky top-0 left-0 right-0 z-[200]"
                    showHistory={() => {
                        setShowMobileElements((c) => ({ ...c, showHistory: !c.showHistory }));
                        if (showMobileElements.showSidebar) {
                            setShowMobileElements((c) => ({ ...c, showSidebar: false }));
                        }
                    }}
                />
            )}

            {showSidebar && (
                <Sidebar
                    selected={(v: string) => {
                        localStorage.setItem('activeModule', v);
                        setSelected(v);
                    }}
                    style={{
                        width: sidebarCollapse ? '3vw' : '20vw',
                    }}
                    collapsed={(b: boolean) => {
                        setSidebarCollapse(b);
                    }}
                    className="flex-1 h-screen"
                />
            )}

            <Sidebar
                onMobileClose={() => setShowMobileElements((c) => ({ ...c, showSidebar: false }))}
                style={{
                    position: 'fixed',
                    transform: showMobileElements.showSidebar ? 'translateX(0)' : 'translateX(-20rem)',
                    transition: 'all 0.2s ease',
                }}
                isMobile={isMobile}
                className="top-0 bottom-0 z-[200] left-0 w-64 h-[100vh!important]"
                selected={(v) => {
                    navigate(`/dashboard/${v}`);
                }}
            />
            {isMobile && (
                <History
                    data={history ?? []}
                    onClose={() => {
                        setShowMobileElements((c) => ({ ...c, showHistory: false }));
                    }}
                    isMobile={isMobile}
                    className="transition-all bg-white"
                    collapsed={() => {
                        setShowMobileElements((c) => ({ ...c, showHistory: false }));
                    }}
                    click={selectedHistory}
                    style={{
                        zIndex: '400',
                        position: 'fixed',
                        width: showMobileElements.showHistory ? '75vw' : '0',
                        opacity: showMobileElements.showHistory ? '1' : '0',
                        userSelect: showMobileElements.showHistory ? 'all' : 'none',
                    }}
                />
            )}
            <section
                style={{
                    width: showSidebar ? (sidebarCollapse ? '97vw' : '80vw') : '100vw',
                }}
                className="flex flex-col h-screen w-fit"
            >
                <div>
                    {showSchoolHeader ? (
                        <SchoolHeader schoolAdminPageName={schoolAdminPageName} showInput={showInput} />
                    ) : (
                        <>
                            {showTop && !isMobile && (
                                <TopNavBar
                                    canNavigateHome={canNavigateHome}
                                    sidebarCollapse={sidebarCollapse}
                                    onShowHistoryOption={() => {
                                        setShowHistoryModal(true);
                                    }}
                                    showHistoryOption={showHistoryOption ?? false}
                                    isMobile={isMobile}
                                    topChild={topExtraData}
                                    onClickPage={onClickPage}
                                    showPagePath={showPagePath}
                                    gmindTyping={gmindTyping ?? undefined}
                                    packageName={undefined}
                                />
                            )}
                        </>
                    )}
                </div>
                <div className={'min-h-screen w-full max-md:w-screen overflow-auto ' + middleClass}>{children}</div>
            </section>
            {showHistory && PathName === 'chats' && (
                <History
                    click={selectedHistory}
                    data={history ?? []}
                    style={{}}
                    isHidden={(v: boolean) => setHistoryHidden(v)}
                    className=""
                    onClose={() => {
                        setHistoryCollapse(!historyCollapse);
                    }}
                />
            )}
            {/* {PathName !== 'chat' && ( */}
            <HistoryModal
                click={(id: string) => {
                    selectedHistory(id);
                }}
                data={history ?? []}
                onClose={() => {
                    setShowHistoryModal(false);
                }}
                hidden={showHistoryModal}
                topChild={topExtraData}
                closeId={closeIdValue}
                onDeleteHistory={onDeleteHistory}
                onDeleteAllHistory={() => {
                    if (onDeleteAllHistory) {
                        onDeleteAllHistory();
                    }
                }}
            />
            {/* )} */}
            <ArticleModal
                click={(id: string) => {
                    selectedHistory(id);
                }}
                data={rightComponentData ?? []}
                onClose={() => {
                    onCloseHistory ? onCloseHistory() : '';
                }}
                hidden={historyVisible ?? false}
                name={rightComponentName ?? 'Comments'}
            />
            {showRightComponent && (
                <RightComponent
                    isMobile={isMobile}
                    click={rightComponentClick ? rightComponentClick : () => {}}
                    name={rightComponentName ?? ''}
                    placeholder={rightComponentPlaceholder ?? ''}
                    data={rightComponentData ?? []}
                    isHidden={(v: boolean) => setHistoryHidden(v)}
                    className=""
                />
            )}
            {showExpiredModal && (
                <ExpiredPackageModal
                    isVisible={isExpiredVisible}
                    packageName={userPlan?.details?.name ?? 'G-Basic'}
                    amountDetails={userPlan?.details?.amountDetails}
                />
            )}
        </main>
    );
};

export default DashboardFrame;
