import { useCallback, useRef } from 'react';

export function abbreviateFullName(fullName: string): string {
    if (!fullName) {
      throw new Error("Full name is required.");
    }

    const nameParts = fullName.split(" ");
  
    // Extract the first character of each part of the name
    const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join("");
  
    return initials;
  }

 export const getColorByFirstLetter = (name: string): string => {
    const firstLetter = name.charAt(0).toLowerCase();
    
    const colorMap: { [key: string]: string } = {
        a: '#FF5733', // Red-Orange
        b: '#33FF57', // Green
        c: '#3357FF', // Blue
        d: '#F333FF', // Pink
        e: '#FF33A6', // Magenta
        f: '#FFC300', // Yellow
        g: '#DAF7A6', // Light Green
        h: '#C70039', // Red
        i: '#900C3F', // Dark Red
        j: '#E6E6FA', // Purple
        k: '#28B463', // Teal
        l: '#1F618D', // Dark Blue
        m: '#D4AC0D', // Gold
        n: '#F39C12', // Orange
        o: '#E74C3C', // Coral
        p: '#8E44AD', // Violet
        q: '#2ECC71', // Emerald
        r: '#3498DB', // Light Blue
        s: '#E67E22', // Orange
        t: '#16A085', // Sea Green
        u: '#34495E', // Dark Slate
        v: '#7D3C98', // Amethyst
        w: '#F1C40F', // Bright Yellow
        x: '#2C3E50', // Midnight Blue
        y: '#D35400', // Pumpkin
        z: '#27AE60', // Green
    };

    return colorMap[firstLetter] || '#999999'; // Default color if no match
};




// useDebouncer Hook
export function useDebouncer(callback: (...args: any[]) => void, delay: number) {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const debouncedCallback = useCallback((...args: any[]) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }, [callback, delay]);

    return debouncedCallback;
}
