import axios from 'axios';
import { BASE_URL } from '@src/utils/constants';

interface UploadResponse {
  success: boolean;
  message: string;
  data?: any;
}

export async function uploadProfilePicture(file: File): Promise<UploadResponse> {
    const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append('image', file);

  try {
    const response = await axios.post(`${BASE_URL}/user/update/profile/pic`, formData, {
      headers: {

        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return {
        success: true,
        message: 'Profile picture uploaded successfully',
        data: response.data,
      };
      
    } else {
      return {
        success: false,
        message: `Failed to upload profile picture. Status code: ${response.status}`,
      };
    }
  } catch (error: any) {
    return {
      success: false,
      message: error.response?.data?.message || 'An error occurred while uploading the profile picture',
    };
  }
}
