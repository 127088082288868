interface  getStartedProps  {
    onclick: () => void,
    className?: string | undefined
    style?: React.CSSProperties | undefined
    isLoggedIn?: boolean;
    showforFree?: boolean;
}


const GetStartedButton = ({ onclick, className, style, isLoggedIn, showforFree }: getStartedProps) => {

    return (
        <button style={style} onClick={() => onclick()} className={`${className} flex flex-row items-center justify-center rounded-[0.6rem] bg-[var(--gmind-orange)] w-[10rem] text-white font-normal text-center text-[0.8rem] px-5 py-2`}>
           { isLoggedIn ? 'Go to Dashboard' : ( showforFree ? 'Get Started for Free': 'Get Started' ) }
        </button>
    );
}

const GetStartedForFree = ({ onclick, className, style, isLoggedIn }: getStartedProps) => {

    return (
        <button style={style} onClick={() => onclick()} className={`${className} flex flex-row items-center justify-center rounded-[0.6rem] bg-[var(--gmind-orange)] w-[10rem] text-white font-normal text-center text-[0.8rem] px-5 py-2`}>
          {isLoggedIn ? 'Go to Dashboard' : "Try for free"}
        </button>
    );
}


export { 
    GetStartedButton,
    GetStartedForFree 
}
