import React from 'react';

interface SchoolCardProps{
    teachers:string;
    duration:string;
    total_teachers:string;
    percentage:string;


}

const SchoolCard = ({ teachers,duration,total_teachers,percentage}:SchoolCardProps) => {
    return (
        <div className=" bg-white p-[24px] gap-[16px] rounded-[6px] shadow-custom flex flex-col ">
            <div className="text-sm font-medium leading-5 text-left font-Poppins text-[#333237]">{teachers}</div>

            <div className="text-3xl font-semibold text-left text-gray-900 font-Poppins">{total_teachers}</div>

            <div className="flex flex-row items-center gap-1">
                <div className="bg-[#E3F4E3] text-[#65C565]  p-[4px_8px] gap-[10px] rounded-[11.58px]  text-xs font-normal leading-4 text-center font-Poppins">
                    {`+ ${percentage} %`}
            </div>
            <div className='font-Poppins text-xs font-normal leading-4 text-left text-[#333237]'>
                from {duration}
            </div>
        </div>
        </div>
    );
};

export default SchoolCard;
