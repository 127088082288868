/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { toast } from 'react-toastify';
import Profile from '@assets/Avatar.svg';
import { MyProfileIcon } from '@src/components/Svg';
import LoadingComponent from '@src/components/LoadingComponent';
import ShareImage from '@public/share.svg';
import Copy from '@public/copy.svg';
import DownloadImage from '@public/download.svg';
import Textboxex from './components/Textboxex';
import ReusableDialog from './components/CommentModal';
import DashboardFrame from '@src/components/DashboardFrame';
import MarkdownDesign from '@src/components/Markdown';
import { io, Socket } from 'socket.io-client';
import { ChatType, getHistory, handleAddHistory, handleDeleteAllHistory, handleDeleteHistory } from '../../endpoints';
import { prompts } from '@src/utils/prompts';
import { convertStringToDelta, copyData, openEditor, shareResponse } from '@src/utils/app_functions';
import ResponseTextbox from '../../components/responseTextBox';
import axios from 'axios';
import { Share } from '../../components/Share';
import { Download } from '../../components/Share/Download';
import moment from 'moment';
import saveAs from 'file-saver';
import { pdfExporter } from 'quill-to-pdf';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router';
import { Chats } from '../../components/interface/interface';
import BackButton from '@src/components/BackButton';
import ComingSoonPage from '../coming_soon/index.tsx';
import { BASE_URL } from '@src/utils/constants.ts';
import { HistoryTypeFormat } from '@src/core/interfaces/chat.tsx';

const Research = () => {
    const [rememberMe, setRememberMe] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [histories, setHistories] = useState<any>([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    // socket.io
    const [response, setResponse] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [promptSent, setPromptSent] = useState('');
    const [newPromptSent, setNewPromptSent] = useState('');
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [isMobile, setIsMobile] = useState(true);
    const UrlRef = useRef<string>('');
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [canNavigate, setCanNavigate] = useState(false);
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [topic, setTopic] = useState('');
    const [audience, setAudience] = useState('');
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({role: 'assistant', content: ''});


    const pageHistory = 'research';
    const navigate = useNavigate();

    const baseurl = BASE_URL;

    const bottomRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if ( isTyping && response != '' ){
            console.log("scrolling")
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
        
    }, [isTyping, response]);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);



    

    const getPageHistory = () => {
        getHistory(pageHistory).then((response) => {
            if (response?.statusCode === 1) {
                const data = response?.data;
                // const filtered = data?.map((c: any) => {
                //     return [...c.conversation, { id: c._id, date: c.createdAt }];
                // });
                const filtered: HistoryTypeFormat[]  = data?.map((d: any) => {
                    return {
                        'date': d.date,
                        'histories': d.histories?.map((history: any) => {
                            return [
                                ...history.conversation,
                                { chat_id: history?.chat_id },
                                { id: history._id, },
                                { date: history.updatedAt },
                            ];
                        })
                    }
                    
                });
                setHistories(filtered);
            }else {
                setHistories([]); 
            }
        });
    };

    const setClickedHistory = (id: string) => {
        // console.log(id);
        // const filterHistory = histories
        //     .filter((h: any) => {
        //         const { id: history_id } = h[h.length - 1];
        //         return String(history_id) === String(id);
        //     })
        //     .flat()
        //     .filter((h: any) => !h?.id);

        // console.log(filterHistory);
        
        // setPromptSent(filterHistory[filterHistory.length -2].content)
        // if ( filterHistory.length > 1 ){
        //     setResponse(filterHistory[filterHistory.length - 1].content)
        // }
        let filterHistory: any = histories
            .flatMap((historyFormat: any) => historyFormat.histories)
            .filter((history: any) => {
                const chatIdObj = history.find((h: any) => h.id === id);
                return chatIdObj !== undefined;
            })
            .flat();

        // historyId.current = filterHistory.find((h: any) => h.chat_id)?.chat_id;
        filterHistory = filterHistory.filter((h: any) => h?.role && h?.content);
        let userPrompt = filterHistory.find((element: any) => ( element.role == 'user' ) );
        let assistantResponse = filterHistory.find((element: any) => ( element.role == "assistant" ) );
        setPromptSent(userPrompt.content);
        setResponse(assistantResponse.content);
        setShowingHistory(true);
        // setChatList(filterHistory);
    };

    useEffect(() => {
        getPageHistory();
    }, []);

    useEffect(() => {
        const newSocket = io(baseurl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;
            setStreamEndMessage(assistant);
            setShowEditorButton(true);
            setIsTyping(false);
            setCanNavigate(true);


            if (streamId === StreamId.current) {
                StreamId.current = '';
                // setStreaming(false);
                const uuid = uuidv4();
                let id = uuid;
                DocumentId.current = uuid;
                const user = JSON.parse(localStorage.getItem('user') || '');
                try {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: promptSent,
                        value: assistant.content,
                        owner_id: user?.id,
                    });
                } catch (error) {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: promptSent,
                        value: assistant.content,
                    });
                }
                socket.emit('get-documents', user?.id);
            }
            
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const handleStreamEnd = async (prompt: string, data: ChatType ) => {
    
        // let dataToSave: ChatType[]  = [
        //     {
        //         role: 'user',
        //         content: prompt,
        //     },
        //     data
        // ]
        let dataToSave = data;

        await handleAddHistory(dataToSave, pageHistory);
        getPageHistory();
    }

    useEffect(() => {
        if ( !isTyping && response != '' ){
            handleStreamEnd(promptSent, streamEndMessage);
        }

        let base = prompts.research(topic, audience);
        if ( canNavigate && response != '' ){
            openEditor({response, pageId: DocumentId.current, promptSent: promptSent, promptType: base, navigate, pageSocket: socket,});
        }
    }, [response, canNavigate, socket])

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message };
        setChats(msgs);
        setNewPromptSent('');
        setResponse('');

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: prompts.research(topic, audience),
                            type: 'gpt-4'
                        },
                        msgs,
                    ],
                },
            });
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    const handleOpenDialog = () => {
        setDialogVisible(true);
    };

    const handleCloseDialog = () => {
        setDialogVisible(false);
    };

    const handleSaveComment = (comment: any) => {
        console.log('Saved comment:', comment);
        handleCloseDialog();
    };

    const regenerate = () => {
        onGenerateHandler(promptSent);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const onFinish = (values: any, fromInput = false) => {
        let promptMessage = '';
        if (!fromInput) {
            const choose = values.choose;
            const description = values.description;
            const purpose = values.purpose;
            const information = values.information;

            // check if all data is passed
            if (!choose || !purpose) {
                toast.error('Please enter email and password');
                return;
            }

            let decriptionData = description ? `Research Description: ${description}` : ''

            let informationData = information ? `\nAdditional Information:${information}` : '';

            promptMessage = `Create a detailed research on ${purpose} with this additional information: \nField:${choose}${decriptionData}${informationData}`;
        } else {
            promptMessage = newPromptSent;
        }

        setPromptSent(promptMessage);
        onGenerateHandler(promptMessage);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const handlePDF = async () => {
        let data = convertStringToDelta(response);
        const blob = await pdfExporter.generatePdf(data)
        const current_time = moment().format('YYYY-MM-DD HH:mm:ss');
        saveAs(blob as Blob, `gmind_document-${current_time}.pdf`);
    }

    const handleWord = async () => {
        let dataToDownload = response;
        const responseData = await axios.post(
            'https://api-v2.gmind.ai/v1/document/markdown-to-doc',
            { content: dataToDownload },
            {},
        );
        const { data: d } = responseData;
        if (d?.statusCode === 1) {
            const link = document.createElement('a');
            link.href = `https://api-v2.gmind.ai/${d?.url}`;
            const current_time = moment().format('YYYY-MM-DD HH:mm:ss');

            link.download = `gmind_document-${current_time}.docx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success('Download successful');
        }
    }

    const handleTranscribed = () => {};

    const handleShare = async () => {
        // const data = Document.current?.data?.ops;
        // const to_markdown = deltaToMarkdown(data);
        

        if (UrlRef.current) {
            setShowShareModal(true);
            return;
        }
        const responseData = await axios.post(
            'https://api-v2.gmind.ai/v1/document/markdown-to-doc',
            { content: response },
            {},
        );

        const { data: d } = responseData;
        if (d?.statusCode === 1) {
            const url = `https://api-v2.gmind.ai/${d?.url}`;
            UrlRef.current = url;
            setShowShareModal(true);
        }
    }   

    const handleShareClick = () => {
        handleShare();
    };

    const handleCopyClick = () => {
        copyData(response);
    };

    const handleMessageClick = () => {
        handleOpenDialog();
    };
    const handleLikeClick = () => {};
    const handleDisLikeClick = () => {};
    const handleDownloadClick = () => setShowDownload(true);

    const promptOptionsData = [
        {
            name: 'Share',
            icon: ShareImage,
            onClick: handleShareClick,
        },
        {
            name: 'Copy',
            icon: Copy,
            onClick: handleCopyClick,
        },
        {
            name: 'Download',
            icon: DownloadImage,
            onClick: handleDownloadClick,
        },
    ];

    const handleDeleteContentHistory = async (id: string) => {
        await handleDeleteHistory(id, pageHistory);
        getPageHistory();
        toast.success("Chat deleted successfully");
    }

    const handleDeleteAllAssesmentHistory = async () => {
        await handleDeleteAllHistory(pageHistory);
        getPageHistory();
        toast.success("Chat cleared successfully");
    }

    return (
        <DashboardFrame
            showSidebar={!isMobile}
            showHistory={!isMobile}
            showTop={!isMobile}
            history={histories}
            selectedHistory={(v: string) => {
                setClickedHistory(v);
            }}
            showPagePath={false}
            onDeleteHistory={(id: string) => {
                handleDeleteContentHistory(id);
            } }
            onDeleteAllHistory={() => handleDeleteAllAssesmentHistory()}
            canNavigateHome={true}
            showHistoryOption={true}
        >
            {!generate && !showingHistory ? (
                <div className="w-full h-[90vh] overflow-y-auto flex flex-col gap-0 bg-white">
                    
                    <div className="w-full flex flex-row gap-0 bg-white">
                        <div className="w-full flex flex-row gap-0 bg-white">
                            <div className="w-full flex justify-center flex-col gap-0 bg-white px-5 md:px-10 py-10">
                                <BackButton />
                                <div className="text-[2rem] md:text-[2.75rem] curriculum--title">Make Research</div>
                                <div className="text-[1.125rem] md:text-[0.875rem] my-[1.25rem] curriculum--subtitle">
                                    Provide us the information requested below to help with your research.
                                </div>
                                <Form layout="vertical" className='flex flex-col md:flex-row flex-wrap gap-x-5' onFinish={onFinish} initialValues={{ rememberMe }}>
                                    <Form.Item
                                        className='w-full md:w-[48%]'
                                        name={'choose'}
                                        label={
                                            <label className="curriculum--label">
                                                {' '}
                                                Which field do you want to research on?
                                            </label>
                                        }
                                        rules={[
                                            {
                                                message: 'field is required',
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <input
                                            onChange={(e) => setTopic(e.target.value)}
                                            className='w-full'
                                            placeholder="e.g Engineering"
                                            style={{
                                                border: '1px solid #DBDADE',
                                                borderRadius: '4px',
                                                backgroundColor: 'transparent',
                                                padding: '7px 10px',
                                            }}
                                            type="text"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className='w-full md:w-[48%]'
                                        name={'purpose'}
                                        label={
                                            <label className="curriculum--label">
                                                {' '}
                                                What is the purpose of the research
                                            </label>
                                        }
                                        rules={[
                                            {
                                                message: 'purpose is required',
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <input
                                            className='w-full'
                                            placeholder="e.g To educate students within ages 14 and 18"
                                            style={{
                                                border: '1px solid #DBDADE',
                                                borderRadius: '4px',
                                                backgroundColor: 'transparent',
                                                padding: '7px 10px',
                                            }}
                                            type="text"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className='w-full md:w-[48%]'
                                        name={'description'}
                                        label={<label className="curriculum--label">Target Audience (optional)</label>}
                                        rules={[
                                            {
                                                message: 'description is required',
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <input
                                            onChange={(e) => setAudience(e.target.value)}
                                            className='w-full'
                                            placeholder="Enter content description"
                                            style={{
                                                border: '1px solid #DBDADE',
                                                borderRadius: '4px',
                                                backgroundColor: 'transparent',
                                                padding: '7px 10px',
                                            }}
                                            type="text"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className='w-full md:w-[48%]'
                                        name={'information'}
                                        label={<label className="curriculum--label"> Additional Information</label>}
                                        rules={[
                                            {
                                                message: 'field is required',
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <Input.TextArea
                                            className='w-full h-[5rem]'
                                            placeholder="e.g Make it comprehensive"
                                            style={{
                                                border: '1px solid #DBDADE',
                                                borderRadius: '4px',
                                                backgroundColor: 'transparent',
                                                padding: '7px 10px',
                                            }}
                                            rows={3}
                                        />
                                    </Form.Item>
                                    

                                    
                                    <button type="submit" className="text-white transcribe">
                                        Generate
                                    </button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div ref={bottomRef} className="w-full flex flex-col gap-0 bg-white h-full overflow-auto">
                    <div className="w-full flex flex-row gap-0 bg-white">
                        <div
                            style={{
                                paddingRight: '91px',
                                paddingLeft: '91px',
                                paddingTop: '40px',
                            }}
                            className="w-full flex flex-col gap-0 bg-white"
                        >
                            {
                                    showingHistory && <BackButton
                                        onclick={() => {
                                            setShowingHistory(false)
                                            if ( generate ){
                                                setGenerate(false)
                                            }
                                        }}
                                    />                                
                                }
                            <div className="w-full flex flex-row gap-0 bg-white ">
                                <div
                                    style={{
                                        width: '661px',
                                        height: '70px',
                                        backgroundColor: '#F9FAFC',
                                        fontFamily: 'Inter',
                                        fontSize: '0.8125rem',
                                        fontWeight: '400',
                                        paddingRight: '24px',
                                        paddingLeft: '24px',
                                        paddingTop: '16px',
                                        paddingBottom: '16px',
                                    }}
                                    className="w-full flex flex-row gap-10 bg-white "
                                >
                                    <img src={Profile} alt="profile" />
                                    <span>{promptSent}</span>
                                </div>
                            </div>

                            <div style={{ marginTop: '23px' }}>
                                {generating ? (
                                    <LoadingComponent isMobile={false} width={''} />
                                ) : (
                                    <>
                                        <ResponseTextbox
                                            content={<MarkdownDesign className="">{response}</MarkdownDesign>}
                                            options={promptOptionsData}
                                            disLikeClick={handleDisLikeClick}
                                            messageClick={handleMessageClick}
                                            likeClick={handleLikeClick}
                                            profileClick={handleCopyClick}
                                            regenerateClick={regenerate}
                                        />
                                        <Input
                                            placeholder="Describe what you want"
                                            value={newPromptSent}
                                            onChange={(e) => setNewPromptSent(e.target.value)}
                                            style={{
                                                height: '50px',
                                                borderRadius: '16px',
                                                border: '1px solid lightgrey',
                                                marginTop: '17px',
                                            }}
                                            suffix={
                                                <svg
                                                    onClick={() => onFinish('', true)}
                                                    width="36"
                                                    height="36"
                                                    viewBox="0 0 36 36"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M15 18L31.5 18"
                                                        stroke="#E55109"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M31.5004 18.0004L13.1254 26.6254C12.9116 26.7049 12.6711 26.6524 12.5098 26.4911C12.3485 26.3298 12.296 26.0893 12.3754 25.8754L15.0004 18.0004L12.3754 10.1254C12.296 9.91158 12.3485 9.67107 12.5098 9.50977C12.6711 9.34846 12.9116 9.29598 13.1254 9.37542L31.5004 18.0004"
                                                        stroke="#E55109"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            }
                                        />
                                        <div className="advise">
                                            Gmind can make mistakes. It's advisable to verify crucial information.
                                        </div>

                                        <ReusableDialog
                                            visible={dialogVisible}
                                            title="Add Comment"
                                            inputPlaceholder="Enter your comment"
                                            saveLabel="Save"
                                            onCancel={handleCloseDialog}
                                            onSave={handleSaveComment}
                                        />
                                        {showShareModal && <Share url={UrlRef.current} onClose={() => setShowShareModal(false)} />}
                                        {showDownload && (
                                            <Download
                                                handlePDF={handlePDF}
                                                handleWord={handleWord}
                                                url={UrlRef.current}
                                                onClose={() => setShowDownload(false)}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </DashboardFrame>
    );
};

export default Research;
