/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { MyBigMicIcon, MyProfileIcon, MySmallLogoIcon } from '@components/Svg';
import { toast } from 'react-toastify';
import Share from '@public/share.svg';
import Copy from '@public/copy.svg';
import Download from '@public/download.svg';
import Edit from '@icons/edit.svg';
import { saveAs } from 'file-saver';
import markdownToDelta from 'markdown-to-quill-delta';
import markdownToTxt from 'markdown-to-txt';
import { pdfExporter } from 'quill-to-pdf';
import moment from 'moment';
import axios from 'axios';
import { openEditor } from '@src/utils/app_functions';
import { useNavigate } from 'react-router';
import { Socket, io } from 'socket.io-client';
import { BASE_URL } from '@src/utils/constants';

const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

interface promptOptionsType {
    name: string;
    icon: string;
    onClick: (e: string) => void;
}

const RecordAudioBox = ({ back }: { back?: () => void }) => {
    const [isRecording, setIsRecording] = useState(false);
    const recognitionRef = useRef<any>(null);
    const [completeTranscript, setCompleteTranscript] = useState<any[]>([]);
    const [transcript, setTranscript] = useState('');
    const [recordingComplete, setRecordingComplete] = useState(false);
    const waveformContainerRef = useRef<HTMLDivElement | null>(null);
    const [socket, setSocket] = useState<Socket | null>(null);
    const navigate = useNavigate();


    const startRecording = () => {
        try {
            toast.success('recording in Progress...');
            setIsRecording(true);
            // @ts-ignore
            recognitionRef.current = new window.webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setCompleteTranscript(texts);
                setTranscript(transcript);
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        return () => {
            // Stop the speech recognition if it's active
            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
        };
    }, []);

    const stopRecording = () => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                // Stop the speech recognition and mark recording as complete
                recognitionRef.current.stop();
                setRecordingComplete(true);
                setIsRecording(true);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const promptOptionsData: Array<promptOptionsType> = [
        {
            name: 'Copy',
            icon: Copy,
            onClick: () => handleCopyText(),
        },
        {
            name: 'Download',
            icon: Download,
            onClick: () => handleWord(),
        },
        {
            name: 'Open Editor',
            icon: Edit,
            onClick: () => handleOpenEditor(),
        },
    ];

    async function handlePDF() {
        const delta = markdownToDelta(transcript);
        const blob = await pdfExporter.generatePdf({ ops: delta });
        const current_time = moment().format('YYYY-MM-DD HH:mm:ss');
        saveAs(blob as Blob, `gmind_document-${current_time}.pdf`);
    }

    async function handleOpenEditor() {
        let tesToDownload = '';
        completeTranscript.forEach((element) => {
            tesToDownload += element;
        })

        openEditor({
            response: tesToDownload,
            promptSent: '',
            promptType: '',
            navigate,
            fromPage: "transcriber",
            pageSocket: socket,
        })
        
    }

    async function handleWord() {
        let tesToDownload = '';
        completeTranscript.forEach((element) => {
            tesToDownload += element;
        })

        const to_markdown = markdownToTxt(tesToDownload);

        const response = await axios.post(
            'https://api-v2.gmind.ai/v1/document/markdown-to-doc',
            { content: to_markdown },
            {},
        );
        const { data: d } = response;
        if (d?.statusCode === 1) {
            const link = document.createElement('a');
            link.download = 'document.docx';
            link.href = `https://api-v2.gmind.ai/${d?.url}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success('Download successful');
        }
    }

    function handleCopyText() {
        navigator.clipboard
            .writeText(transcript)
            .then(() => {
                console.log('Written');
                toast.success('Content copied to clipboard!');
            })
            .catch((err) => {
                console.log('Failed, ', err);
            });
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="record--audio--box">
                    <div className="profile--row">
                        <MyProfileIcon /> <span style={{ margin: '10px' }}> Record Audio To Transcribe</span>
                    </div>
                    <div>
                        <span style={{ margin: '10px' }}>Click on the button below to get started</span>
                    </div>
                    <div className="mt-[5px]">
                        <div className="recorded--audio--controls">
                            <div role="button" onClick={() => startRecording()} className="circle">
                                Record
                            </div>
                            <div role="button" onClick={() => stopRecording()} className="circle">
                                Stop
                            </div>
                            <div ref={waveformContainerRef} style={{ width: '267px', height: '1000px' }} />
                            <div
                                style={{
                                    fontFamily: 'Inter',
                                    fontWeight: 400,
                                    fontSize: '18px',
                                    color: '#E55109',
                                }}
                            >
                                {/* {formatTime(totalRecordingTime)} */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-[30px]">
                    <div className="mb-[20px]" style={{ display: 'flex', flexDirection: 'column' }}>
                        <MySmallLogoIcon />
                        <div className="mt-[15px]">{completeTranscript}</div>
                    </div>
                </div>

                <div className="flex items-center justify-between w-full">
                    <div className="flex gap-x-10">
                        {promptOptionsData.map((option, index) => (
                            <div
                                role="button"
                                key={index}
                                onClick={(e: any) => option.onClick(e)}
                                className=" rounded-lg items-center px-2 py-1 bg-[var(--gmind-header)] flex gap-x-2"
                            >
                                <img src={option.icon} className="h-3" alt="" />
                                <div className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]">
                                    {option.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default RecordAudioBox;
