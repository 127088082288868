import { useEffect, useRef, useState } from "react";
import ExampleCard from "../../../../components/example_card";
import curve from "@assets/curve.svg";
import learningGmind from "@assets/learning_gmind.svg";
import { useNavigate } from "react-router";
import { GetStartedForFree } from "@src/components/GetStartedButton";
import userIcon from "@icons/user_icon.svg";

const About = ({isLoggedIn}: {isLoggedIn?: boolean;}) => {

    const texts = [
        'Creators',
        'Teachers',
        'Learners'
      ];
    
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentText, setCurrentText] = useState('');
    const textRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
    const intervalId = setInterval(() => {
        if (textRef.current) {
        const nextText = currentText.substring(0, currentText.length - 1);
        setCurrentText(nextText);
        if (nextText.length === 0) {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
            setCurrentText(texts[currentIndex]);
        }
        }
    }, 2000); // Adjust interval for desired speed

    return () => clearInterval(intervalId);
    }, [texts.length]); 

    return (
        <>
            <div className="flex flex-row mt-1 w-[100%] md:hidden">
                <div className=" w-full gap-[5%] flex flex-row justify-between items-center">
                    <div className="w-full  md:w-[50%] flex flex-col gap-y-5 mt-5">
                        <h3 className="font-medium text-[2rem] md:text-[2.5rem] text-[var(--gmind-black)]">
                        Leading AI tool for<span ref={textRef} className="text-[var(--gmind-orange)]"> {texts[currentIndex]} </span>.
                        </h3>
                        <h2 className="font-normal text-[1rem] text-[var(--gmind-light-black)]">
                        From designing curriculum to drafting lesson plans, creating attention-grabbing presentations and research papers, to forming unique questions and answers, GMind AI stands as a powerhouse to escalate the capabilities of each individual.
                        </h2>

                        <GetStartedForFree isLoggedIn={isLoggedIn} className="hidden md:flex w-[10rem]" onclick={() => {
                            if ( isLoggedIn ) {
                                navigate("/dashboard/chat");
                            }else {
                            navigate("/signup?forFree=1"); 
                            } 
                        }}  />

                        <div className="self-end flex gap-x-3">
                            <img src={curve} alt="curve" />
                            <img src={learningGmind} alt="learning gmind" />
                        </div>
                    </div>
                    
                    <ExampleCard />
                </div>

            </div>

            {/* {new hero section} */}
            <div className="hidden md:flex flex-col gap-y-10 py-[5vh] z-50">
                <div className="w-full flex items-center gap-x-3 justify-between">
                    <div className="self-start flex gap-x-5 mt-20 w-[19vw]">
                        <img src={curve} alt="curve" />
                        {/* <img className="w-[130px]" src={learningGmind} alt="learning gmind" /> */}
                    </div>

                    <div className="flex flex-col gap-y-5 w-[50vw]">
                        <h3 className="font-[Poppins] font-bold text-[2rem] md:text-[2.5rem] text-[var(--gmind-black)] text-center">
                        Simplifying teaching & content development with AI for <span ref={textRef} className="text-[var(--gmind-orange)]"> {texts[currentIndex]}</span>
                        </h3>
                        
                        <p className="font-[Poppins] text-[0.9rem] font-normal text-[var(--gmind-light-black)] text-center">GMind AI is a cutting-edge tool for educators. The leading AI-empowered educational tool that enhances teaching practices with time-saving solutions. From designing curriculum to drafting lesson plans, creating attention-grabbing presentations and research papers, to forming unique questions and answers, GMind AI stands as a powerhouse to escalate the capabilities of each individual.</p>

                        <div className="self-center flex gap-x-3">
                            <div onClick={() => navigate('/features?showSection=1') } className="cursor-pointer font-[Poppins] border-solid text-[var(--gmind-orange)] text-[0.8rem] border-[1px] rounded-[0.625rem] border-[var(--gmind-orange)] px-[1.25rem] py-[0.6rem] bg-transparent">Watch Demo</div>
                            <div onClick={() => {
                                if ( isLoggedIn ) {
                                    navigate("/dashboard/chat");
                                }else {
                                    navigate("/signup?forFree=1"); 
                                } 
                            }} className="cursor-pointer font-[Poppins] border-solid text-white text-[0.8rem] border-[1px] rounded-[0.625rem] border-[var(--gmind-orange)] px-[1.25rem] py-[0.6rem] bg-[var(--gmind-orange)]">{isLoggedIn ? 'Dashboard' : 'Try it Now for Free'}</div>
                        </div>
                    </div>

                    <div className="self-end py-5 flex flex-col items-center justify-end w-[19vw]">
                        <div className="p-3 flex items-center justify-between gap-x-4 rounded-[1rem] bg-white shadow-md ">
                            <p className="text-black font-[Poppins] text-[0.8rem] font-normal" >Hi, I’m GMind! Ask me anything</p>
                            <img src={userIcon} alt="userIcon" />
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}


export default About