import Header from "../components/header";
import { useLocation, useNavigate } from "react-router";
import ExampleCard from "@src/components/example_card";
import SignUp from "./components/SinUp";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";



export default function SignUpPage() {
    return (
        <div className="w-full flex flex-col h-screen bg-white gap-y-3 py-0 px-5 md:px-20">
            <Helmet>
                <link rel="canonical" href="https://gmind.ai/signup" />
                <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
                <meta name="description" content="Best AI for educators, save time planning, creating teaching materials and get support for teaching practice" />
                <title>Sign Up</title>
                <meta name="keywords" content="gmind, ai, chat" />
            </Helmet>
            {/* <div className=" w-full md:w-full">
                <Header isLoggedIn={false} onClickFaQ={() => {}} onClickFeature={() => {} } onClickPricing={() => {}} />
            </div> */}
            
            <div className="flex flex-col md:flex-row items-center justify-between mt-3">
                <SignUp />
                <div className="hidden md:block">
                    <ExampleCard />
                </div>
            </div>
            <div className="hidden md:block">
                <ToastContainer />
            </div>
        </div>
    );
}

